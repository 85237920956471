import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Category from 'models/categories/category.class';
import { PaginatedResponse } from 'models/common/paginated-response.interface';

interface CategoriesState {
  all: PaginatedResponse<Category> | null;
  selected: Category | null;
  loading: boolean;
}

export const initialState: CategoriesState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategoriesStart: state => {
      state.loading = true;
    },
    fetchCategoriesSuccess: (state, action: PayloadAction<PaginatedResponse<Category>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchCategoriesError: state => {
      state.loading = false;
    },
    fetchCategorySuccess: (state, action: PayloadAction<Category>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCategoryStart: state => {
      state.loading = true;
    },
    saveCategorySuccess: (state, action: PayloadAction<Category>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCategoryError: state => {
      state.loading = false;
    },
    resetCategoriesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  fetchCategorySuccess,
  resetCategoriesState,
  saveCategoryStart,
  saveCategorySuccess,
  saveCategoryError,
} = slice.actions;

export default slice.reducer;
