import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Catalog } from 'models/catalogs/catalog.class';
import { PaginatedResponse } from 'models/common/paginated-response.interface';

interface CatalogsState {
  all: PaginatedResponse<Catalog> | null;
  selected: Catalog | null;
  loading: boolean;
}

export const initialState: CatalogsState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    fetchCatalogsStart: state => {
      state.loading = true;
    },
    fetchCatalogsSuccess: (state, action: PayloadAction<PaginatedResponse<Catalog>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchCatalogsError: state => {
      state.loading = false;
    },
    fetchCatalogSuccess: (state, action: PayloadAction<Catalog>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCatalogStart: state => {
      state.loading = true;
    },
    saveCatalogSuccess: (state, action: PayloadAction<Catalog>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCatalogError: state => {
      state.loading = false;
    },
    resetCatalogsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchCatalogsStart,
  fetchCatalogsSuccess,
  fetchCatalogsError,
  fetchCatalogSuccess,
  resetCatalogsState,
  saveCatalogStart,
  saveCatalogError,
  saveCatalogSuccess,
} = slice.actions;

export default slice.reducer;
