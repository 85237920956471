import { ExcelAmounts } from 'models/excel/excel-amounts';
import { ExcelFileResponse } from 'models/excel/excel-file.interface';
import api from './api';
import EntitiesService from './entities.service';

export default class ExcelService extends EntitiesService<number[]> {
  get endpoint() {
    return '/excel';
  }

  async fetchExcels(excelAmounts: ExcelAmounts): Promise<number[] | ExcelFileResponse> {
    const response = await api.post<number[]>(`${this.endpoint}`, excelAmounts);

    return response?.data;
  }
}
