import i18next from 'i18next';
import { ToastOptions } from 'models/common/toast-options.interface';
import { ApiErrorMessage } from 'models/enums/api-error-message.enum';

function getToast(translationKey: string, type = 'error'): ToastOptions {
  return {
    type: type,
    toastId: 'apiError',
    content: i18next.t(`errorMessages.${translationKey}`),
  } as ToastOptions;
}

export function getErrorToast(messageString: ApiErrorMessage | string): ToastOptions | undefined {
  switch (messageString) {
    case ApiErrorMessage.UserNotFound:
    case ApiErrorMessage.WrongPassword:
      return getToast('loginMismatch');
    case ApiErrorMessage.EmailNotFound:
      return getToast('emailNotFound');
    case ApiErrorMessage.TokenNotFound:
      return getToast('tokenNotFound');
    case ApiErrorMessage.UserExists:
    case ApiErrorMessage.EmailAlreadyInUse:
      return getToast('userExists');
    case ApiErrorMessage.TokenExpiredError:
      return getToast('tokenExpired');
    case ApiErrorMessage.PriceCouldNotBeCalculated:
      return;
    case ApiErrorMessage.FurnitureSpecificPriceError:
      return;
    case ApiErrorMessage.OrderAlreadyExists:
      return getToast('orderExists', 'warning');
    case ApiErrorMessage.ConfirmedOrderAlreadyExists:
      return getToast('confirmedOrderExists', 'warning');
    default:
      return getToast('defaultError');
  }
}

export function apiErrorMessageToTranslationKey(messageString: ApiErrorMessage | string): string {
  const errorMessagesKey = 'errorMessages';

  switch (messageString) {
    case ApiErrorMessage.UserNotFound:
    case ApiErrorMessage.WrongPassword:
      return `${errorMessagesKey}.loginMismatch`;

    case ApiErrorMessage.EmailNotFound:
      return `${errorMessagesKey}.emailNotFound`;

    case ApiErrorMessage.TokenNotFound:
      return `${errorMessagesKey}.tokenNotFound`;

    case ApiErrorMessage.UserExists:
    case ApiErrorMessage.EmailAlreadyInUse:
      return `${errorMessagesKey}.userExists`;
    case ApiErrorMessage.TokenExpiredError:
      return `${errorMessagesKey}.tokenExpired`;
    case ApiErrorMessage.IncorrectCurrentPassword:
      return `${errorMessagesKey}.incorrectCurrentPassword`;
    default:
      return `${errorMessagesKey}.defaultError`;
  }
}
