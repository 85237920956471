import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Grip } from 'models/grips/grip.class';

interface GripsState {
  all: PaginatedResponse<Grip> | null;
  selected: Grip | null;
  loading: boolean;
}

export const initialState: GripsState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'grips',
  initialState,
  reducers: {
    fetchGripsStart: state => {
      state.loading = true;
    },
    fetchGripsSuccess: (state, action: PayloadAction<PaginatedResponse<Grip>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchGripsError: state => {
      state.loading = false;
    },
    fetchGripSuccess: (state, action: PayloadAction<Grip>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveGripStart: state => {
      state.loading = true;
    },
    saveGripSuccess: (state, action: PayloadAction<Grip>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveGripError: state => {
      state.loading = false;
    },
    resetGripsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchGripsStart,
  fetchGripsSuccess,
  fetchGripsError,
  fetchGripSuccess,
  resetGripsState,
  saveGripStart,
  saveGripSuccess,
  saveGripError,
} = slice.actions;

export default slice.reducer;
