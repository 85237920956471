import { Space } from 'models/spaces/space.class';
import api from './api';
import EntitiesService from './entities.service';

export default class SpacesService extends EntitiesService<Space> {
  get endpoint() {
    return '/spaces';
  }

  private handleFormDataFiles(files: File[]) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('images', file);
    });
    return formData;
  }

  async uploadSpaceFile(spaceId: string, images: File[]) {
    const formData = this.handleFormDataFiles(images);

    try {
      const response = await api.post(`${this.endpoint}/picture/${spaceId}`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async removeSpaceFile(spaceId: string, fileId: string) {
    try {
      const response = await api.delete(`${this.endpoint}/picture/${spaceId}/${fileId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
