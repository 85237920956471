import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import i18next from 'lib/i18next';
import { ToastOptions } from 'models/common/toast-options.interface';
import { ApiErrorMessage } from 'models/enums/api-error-message.enum';
import store from 'store';
import { addToast } from 'store/app/AppSlice';
import { logOutUser } from 'store/auth/AuthActions';
import { getErrorToast } from 'utils/api-error-to-translation-key';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const createToastForError = (error: AxiosError) => {
  const toast: ToastOptions = {
    type: 'error',
    toastId: 'apiError',
    content: '',
  };

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // toast.content = i18next.t(apiErrorMessageToTranslationKey(error.response?.data?.message || ''));
    return getErrorToast(error.response?.data?.message || '');
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser
    toast.content = i18next.t('errorMessages.apiOffline');
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.content = i18next.t('errorMessages.defaultError');
  }

  return toast;
};

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token') || '';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

api.interceptors.response.use(
  (success: AxiosResponse) => success,
  (error: AxiosError) => {
    // Throws errors when api call is to the authController,
    // these errors are showed in the component itself instead of in a Toast
    if (error.response && error.response.config.url && error.response.config.url.includes('auth')) {
      throw error;
    } else {
      if (error.response?.data?.message === ApiErrorMessage.TokenExpiredError) {
        store.dispatch(logOutUser());
        return;
      }
      const toast = createToastForError(error);

      toast && store.dispatch(addToast(toast));

      throw error;
    }
  },
);

export default api;
