import { unwrapResult } from '@reduxjs/toolkit';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Space } from 'models/spaces/space.class';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { fetchSpaces } from 'store/spaces/SpacesActions';

export const useSpaces = (initialParams = new FetchAllParams()) => {
  const dispatch = useAppDispatch();
  const _fetchSpaces = (params?: FetchAllParams) => dispatch(fetchSpaces(params));
  const [params, setParams] = useState<FetchAllParams>(initialParams);

  const [paginatedSpaces, setPaginatedSpaces] = useState<PaginatedResponse<Space>>(
    new PaginatedResponse(),
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const response = await _fetchSpaces(params);
      if (response.payload) {
        setPaginatedSpaces(unwrapResult(response));
      }
      setLoading(false);
    })();
  }, [params]);

  return {
    params,
    setParams,
    paginatedSpaces,
    loading,
  };
};
