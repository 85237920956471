import { BaseModel } from 'models/base-model.class';
import { Catalog } from 'models/catalogs/catalog.class';
import { Color } from 'models/colors/color.class';
import { EdgeColor } from 'models/edge-colors/edge-color.class';
import { Grip } from 'models/grips/grip.class';
import { Model } from 'models/models/model.class';
import { DrawerType } from './drawer-type.enum';
import { GripDirection } from './grip-direction.enum';

export class ProjectCatalog extends BaseModel {
  id?: string;
  catalog: Catalog = new Catalog();
  model?: Model;
  frontColor?: Color;
  frontEdgeColor?: EdgeColor;
  korpusEdgeColor?: EdgeColor;
  insideColor?: Color;
  grip?: Grip;
  gripDirection?: GripDirection;
  gripColor?: Color;
  plinthColor?: Color;
  plinthHeight?: number;
  legHeight?: number;
  worksheetColor?: Color;
  workingHeight?: number;
  drawerType?: DrawerType;
  priceCategory?: number;
  editable = true;
}
