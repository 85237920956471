import { unwrapResult } from '@reduxjs/toolkit';
import AppRoutes from 'AppRoutes';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import { EventEmitter } from 'events';
import { useExcel } from 'hooks-data/use-excel';
import { usePdfs } from 'hooks-data/use-pdfs';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { TestIds } from 'models/enums/test-ids.enum';
import { ProjectArticle } from 'models/projects/project-article.class';
import React, { createContext, useContext, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { history, useAppDispatch } from 'store';
import { selectIsLoading } from 'store/app/AppSelectors';
import { setLanguage, setPDFLoadingFailed } from 'store/app/AppSlice';
import { selectIsLoggedIn } from 'store/auth/AuthSelectors';
import { moveToNewOrder } from 'store/projects/ProjectsActions';
import { FrakoEvents } from 'utils/frako-events.enum';
import './App.scss';

export const FrakoContext = createContext({
  emitter: new EventEmitter(),
});

const App: React.FC = () => {
  const loading = useSelector(selectIsLoading);
  const isAuthenticated = useSelector(selectIsLoggedIn);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const _setLanguage = () => dispatch(setLanguage(language));
  const _setPDFLoadingFailed = () => dispatch(setPDFLoadingFailed());
  const _moveDeliveryArticlesToNewOrder = (deliveryProjectArticles: ProjectArticle[]) =>
    dispatch(moveToNewOrder(deliveryProjectArticles));

  const { emitter } = useContext(FrakoContext);
  const { i18n } = useTranslation();
  const { language } = i18n;

  const {
    handleFetchPdfs,
    pdfBlob,
    zipBlob: pdfZipBlob,
    documentReference: pdfDocumentReference,
  } = usePdfs();

  const {
    handleFetchExcelFiles,
    zipBlob: excelZipBlob,
    excelFile,
    documentReference: excelDocumentReference,
  } = useExcel();

  const createNewOrderForSelectedDeliveryArticles = async (
    deliveryArticleList: ProjectArticle[],
  ) => {
    const response = await _moveDeliveryArticlesToNewOrder(deliveryArticleList);

    if (moveToNewOrder.fulfilled.match(response)) {
      const newOrder = unwrapResult(response);

      history.push({
        pathname: `${RoutePaths.PROJECT_OVERVIEW}/${newOrder.project.id}`,
        search: `?docId=${newOrder.id}`,
      });
    }
  };

  // Keeps the store in sync with the defaultLanguage from i18next
  useEffect(() => {
    _setLanguage();
  }, [language]);

  useEffect(() => {
    const hotJarId = process.env.REACT_APP_HOTJAR_ID;
    const hotJarSnippetVersion = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;

    if (hotjar && !hotjar.initialized() && hotJarId && hotJarSnippetVersion) {
      const hjid = parseInt(hotJarId);
      const hjsv = parseInt(hotJarSnippetVersion);

      hotjar.initialize(hjid, hjsv, true);
    }

    emitter.on(FrakoEvents.GET_PDFS, handleFetchPdfs);
    emitter.on(FrakoEvents.MOVE_TO_NEW_ORDER, createNewOrderForSelectedDeliveryArticles);
    emitter.on(FrakoEvents.GET_EXCEL_FILES, handleFetchExcelFiles);

    return () => {
      emitter.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (pdfBlob) {
      emitter.emit(FrakoEvents.UPDATE_PROJECT_ARTICLES_LIST);
      const url = URL.createObjectURL(pdfBlob);

      const newWindow = window.open(url);

      // If the browser blocks the popup, show an alert and set the pdf loading failed
      if (!newWindow) {
        window.alert(t('errorMessages.pdfOpenBlocked'));
        _setPDFLoadingFailed();
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [pdfBlob]);

  useEffect(() => {
    if (pdfZipBlob) {
      emitter.emit(FrakoEvents.UPDATE_PROJECT_ARTICLES_LIST);
      const downloadUrl = URL.createObjectURL(pdfZipBlob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${pdfDocumentReference}-pdf.zip`;

      a.click();

      return () => {
        URL.revokeObjectURL(downloadUrl);
      };
    }
  }, [pdfZipBlob]);

  useEffect(() => {
    if (excelZipBlob) {
      const downloadUrl = URL.createObjectURL(excelZipBlob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${excelDocumentReference}-excel.zip`;

      a.click();

      return () => {
        URL.revokeObjectURL(downloadUrl);
      };
    }
  }, [excelZipBlob]);

  useEffect(() => {
    if (excelFile) {
      const downloadUrl = URL.createObjectURL(excelFile.blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = excelFile.name;

      a.click();

      return () => {
        URL.revokeObjectURL(downloadUrl);
      };
    }
  }, [excelFile]);

  return (
    <div
      className={classNames('app-container', { public: !isAuthenticated })}
      // className={classNames('app-container', { public: !isAuthenticated })}
      data-testid={TestIds.APP_CONTAINER}>
      <FrakoContext.Provider value={{ emitter }}>
        <AppRoutes>
          {loading && <Spinner />}
          <ToastContainer autoClose={8000} />
        </AppRoutes>
      </FrakoContext.Provider>
    </div>
  );
};

export default App;
