import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectCatalog } from 'models/projects/project-catalog.class';

interface ProjectCatalogsState {
  catalogList: ProjectCatalog[];
  selectedProjectCatalog?: ProjectCatalog;
  manualSave: boolean;
}

export const initialState: ProjectCatalogsState = {
  catalogList: [],
  selectedProjectCatalog: undefined,
  manualSave: false,
};

export const slice = createSlice({
  name: 'projects-catalogs',
  initialState,
  reducers: {
    setProjectCatalogList: (state, newCatalogList: PayloadAction<ProjectCatalog[]>) => {
      state.catalogList = newCatalogList.payload;
    },
    addNewCatalogToList: state => {
      state.catalogList.push(new ProjectCatalog());
    },
    setSelectedProjectCatalog: (state, selectedProjectCatalog: PayloadAction<ProjectCatalog>) => {
      state.selectedProjectCatalog = selectedProjectCatalog.payload;
    },
    resetSelectedProjectCatalog: state => {
      state.selectedProjectCatalog = undefined;
    },
    setManualSave: state => {
      state.manualSave = true;
    },
    unsetManualSave: state => {
      state.manualSave = false;
    },
  },
});

export const {
  setProjectCatalogList,
  addNewCatalogToList,
  setSelectedProjectCatalog,
  resetSelectedProjectCatalog,
  setManualSave,
  unsetManualSave,
} = slice.actions;

export default slice.reducer;
