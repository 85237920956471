export enum ApiErrorMessage {
  WrongPassword = 'Wrong password',
  UserNotFound = 'User not found',
  EmailNotFound = 'Email not found',
  UserExists = 'User already exists',
  EmailAlreadyInUse = 'Email already in use',
  TokenNotFound = 'Token not found',
  TokenExpiredError = 'Token expired',
  PriceCouldNotBeCalculated = 'noPrice',
  FurnitureSpecificPriceError = 'furniture specific price error',
  OrderAlreadyExists = 'Order already exists for this project',
  ConfirmedOrderAlreadyExists = 'Confirmed order already exists for this project',
  IncorrectCurrentPassword = 'Current password is not correct',
}
