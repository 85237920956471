import { AxiosRequestConfig } from 'axios';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { generateRequestConfig } from 'utils/generate-request-config.util';
import api from './api';

export default class EntitiesService<T> {
  get endpoint(): string {
    return localStorage.getItem('local_base_url') + '/entities';
  }

  async create(entity: T): Promise<T> {
    const response = await api.post<T>(this.endpoint, entity);

    return response?.data;
  }

  async fetch(params?: FetchAllParams, endpoint?: string): Promise<PaginatedResponse<T>> {
    const config: AxiosRequestConfig = generateRequestConfig(params);

    const response = await api.get<PaginatedResponse<T>>(endpoint || this.endpoint, config);

    return response?.data;
  }

  async fetchAll(params?: FetchAllParams, endpoint?: string): Promise<T[]> {
    const config: AxiosRequestConfig = generateRequestConfig(params);

    const response = await api.get<T[]>(`${endpoint || this.endpoint}/all`, config);

    return response?.data;
  }

  async fetchOne(id: string, endpoint?: string): Promise<T> {
    const response = await api.get<T>(`${endpoint || this.endpoint}/${id}`);

    return response?.data;
  }

  async calculateBeforePrint(id: string, endpoint?: string): Promise<T> {
    const response = await api.put<T>(`${endpoint || this.endpoint}/calculate/${id}`);

    return response?.data;
  }

  async update(id: string, entity: T): Promise<T> {
    const response = await api.put<T>(`${this.endpoint}/${id}`, entity);

    return response?.data;
  }

  async updateMultiple(entity: Partial<T>[]): Promise<T[]> {
    const response = await api.put<T[]>(`${this.endpoint}`, entity);

    return response?.data;
  }

  async delete(id: string): Promise<T> {
    const response = await api.delete(`${this.endpoint}/${id}`);

    return response?.data;
  }

  async restore(id: string): Promise<T> {
    const response = await api.delete(`${this.endpoint}/restore/${id}`);

    return response?.data;
  }
}
