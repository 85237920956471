import { unwrapResult } from '@reduxjs/toolkit';
import { ExcelAmounts } from 'models/excel/excel-amounts';
import { ExcelFile, ExcelFileResponse } from 'models/excel/excel-file.interface';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { setExcelLoading, unsetExcelLoading, unsetExcelLoadingFailed } from 'store/app/AppSlice';
import { fetchExcels } from 'store/app/excel/ExcelActions';
import { fetchPdfs } from 'store/pdf/PdfActions';

export const useExcel = () => {
  const dispatch = useAppDispatch();
  const _fetchExcels = (excelAmounts: ExcelAmounts) => dispatch(fetchExcels(excelAmounts));
  const _setExcelIsLoading = () => dispatch(setExcelLoading());
  const _unsetExcelIsLoading = () => dispatch(unsetExcelLoading());
  const _unsetExcelLoadingFailed = () => dispatch(unsetExcelLoadingFailed());

  const [zipBlob, setZipBlob] = useState<Blob>();
  const [excelFile, setExcelFile] = useState<ExcelFile>();
  const [documentReference, setDocumentReference] = useState<string>();

  const handleFetchExcelFiles = async (excelAmounts: ExcelAmounts) => {
    setDocumentReference(excelAmounts.projectDocumentReference);

    _unsetExcelLoadingFailed();
    _setExcelIsLoading();

    const response = await _fetchExcels(excelAmounts);

    if (fetchExcels.fulfilled.match(response)) {
      const excelZipBuffer = unwrapResult(response);

      if (fetchPdfs.fulfilled.match(response)) {
        if (Array.isArray(excelZipBuffer)) {
          handleSetExcelZip(excelZipBuffer);
        } else if (excelZipBuffer.hasOwnProperty('name')) {
          handleSetExcelFile(excelZipBuffer);
        }
      } else {
        window.alert('Error fetching PDFs');
      }
    }

    _unsetExcelIsLoading();
    return response;
  };

  const handleSetExcelZip = (excelZipBuffer: number[]) => {
    const uint8Array = new Uint8Array(excelZipBuffer);

    if (uint8Array.length === 0) return;

    const excelZipBlob = new Blob([uint8Array], { type: 'application/zip' });

    setZipBlob(excelZipBlob);
  };

  const handleSetExcelFile = (excelFileResponse: ExcelFileResponse) => {
    const uint8Array = new Uint8Array(excelFileResponse.file);

    if (uint8Array.length === 0) return;

    const excelZipBlob = new Blob([uint8Array], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    setExcelFile({ name: excelFileResponse.name, blob: excelZipBlob });
  };

  return {
    handleFetchExcelFiles,
    zipBlob,
    excelFile,
    documentReference,
  };
};
