import bathroom from 'assets/img/showroom/nav-bar/bathroom.png';
import dressing from 'assets/img/showroom/nav-bar/dressing.png';
import kitchen from 'assets/img/showroom/nav-bar/kitchen.png';
import livingSpace from 'assets/img/showroom/nav-bar/livingSpace.png';
import office from 'assets/img/showroom/nav-bar/office.png';
import sleepingPlace from 'assets/img/showroom/nav-bar/sleepingPlace.png';
import { Space } from 'models/spaces/space.class';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getFilePath } from 'utils/helper-functions';

interface ISpacesNavbarProps {
  onRedirect: (url: string) => void;
  allSpaces: Space[];
}

const SpacesNavbar: React.FC<ISpacesNavbarProps> = ({ onRedirect, allSpaces }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getDefaultImage = (space: Space) => {
    switch (space.name.toUpperCase()) {
      case 'KEUKEN':
        return kitchen;
      case 'BADKAMER':
        return bathroom;
      case 'DRESSING':
        return dressing;
      case 'INTERIEUR':
        return livingSpace;
      case 'AFWERKINGEN':
        return sleepingPlace;
      case 'ACCESSOIRES':
        return office;
      case 'GREPEN':
        return kitchen;
      default:
        return '';
    }
  };

  const spacesOptions = allSpaces.map(space => {
    const imagePath = space.images?.length ? getFilePath(space.images[0].path) : getDefaultImage(space);
    // if (imagePath) {
    //   console.log(imagePath);
    // }
    return {
      urlPath: space.name,
      label: space.name,
      src: imagePath,
    }
  });

  if (allSpaces.length) {
    console.log(allSpaces[0]);
  }

  // const spacesOptions = [
  //   {
  //     urlPath: 'Keuken',
  //     label: `${t('showroom.spaces.kitchen')}`,
  //     src: kitchen,
  //   },
  //   {
  //     urlPath: 'Badkamer',
  //     label: `${t('showroom.spaces.bathroom')}`,
  //     src: bathroom,
  //   },
  //   {
  //     urlPath: 'Dressing',
  //     label: `${t('showroom.spaces.dressing')}`,
  //     src: dressing,
  //   },
  //   {
  //     urlPath: 'Leefruimte',
  //     label: `${t('showroom.spaces.livingSpace')}`,
  //     src: livingSpace,
  //   },
  //   {
  //     urlPath: 'Slaapkamer',
  //     label: `${t('showroom.spaces.sleepingPlace')}`,
  //     src: sleepingPlace,
  //   },
  //   {
  //     urlPath: 'Kantoor',
  //     label: `${t('showroom.spaces.office')}`,
  //     src: office,
  //   },
  // ];

  const handleRedirect = (urlPath: string) => {
    onRedirect(urlPath);
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      className="nav-link-dropdown full-screen"
      show={isOpen}
      onToggle={() => setIsOpen(!isOpen)}>
      <Dropdown.Toggle className="bottom-liner" id="dropdown-autoclose-inside">
        <div className="icons">
          <div className="animated-toggle"></div>
        </div>

        <p className="m-0">{t('showroom.navbar.spaces')}</p>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu">
        <div className="desktop nav-link-full-screen-wrapper">
          <div className="spaces-row">
            {spacesOptions.map((option, key) => (
              <div
                key={key}
                className="nav-link-column"
                onClick={() => handleRedirect(option.urlPath)}>
                <button type="submit" className="btn btn-secondary spaces-nav-link-button">
                  {option.label}
                </button>
                <div className="image-wrapper" style={{ backgroundImage: `url(${option.src})` }} />
              </div>
            ))}
          </div>
        </div>

        <div className="mobile">
          <div className="nav-link-items">
            {spacesOptions.map(option => {
              return (
                <Dropdown.Item
                  className="nav-link-item"
                  key={option.urlPath}
                  onClick={() => handleRedirect(option.urlPath)}>
                  {option.label}
                </Dropdown.Item>
              );
            })}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SpacesNavbar;
