import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectConfirmedOrder } from 'models/projects/project-confirmed-order.class';

interface ProjectConfirmedOrdersState {
  selected: ProjectConfirmedOrder | null;
  loading: boolean;
}

export const initialState: ProjectConfirmedOrdersState = {
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'project-confirmed-orders',
  initialState,
  reducers: {
    fetchProjectConfirmedOrderSuccess: (
      state,
      action: PayloadAction<ProjectConfirmedOrder>,
    ): void => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectConfirmedOrderStart: (state): void => {
      state.loading = true;
    },
    saveProjectConfirmedOrderSuccess: (
      state,
      action: PayloadAction<ProjectConfirmedOrder>,
    ): void => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectConfirmedOrderError: (state): void => {
      state.loading = false;
    },
    resetProjectConfirmedOrdersState: (state): void => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchProjectConfirmedOrderSuccess,
  saveProjectConfirmedOrderStart,
  saveProjectConfirmedOrderSuccess,
  saveProjectConfirmedOrderError,
  resetProjectConfirmedOrdersState,
} = slice.actions;

export default slice.reducer;
