import { createAsyncThunk } from '@reduxjs/toolkit';
import { PDFAmount } from 'models/pdf/pdf-amount';
import { PdfType } from 'models/pdf/pdf-types.enum';
import PdfService from 'services/pdf.service';

const pdfService = new PdfService();
// TODO: Remove unnessesary thunks when modal is fully implemented
export const fetchPdf = createAsyncThunk('pdf', async (documentId: string) => {
  return pdfService.fetchOne(documentId);
});

export const fetchConfirmedOrderPdf = createAsyncThunk(
  'confirmed-order/pdf',
  async (documentId: string) => {
    return pdfService.fetchPdf(PdfType.CONFIRMED_ORDER, documentId);
  },
);

export const fetchSawArticlesPdf = createAsyncThunk(
  'confirmed-order/pdf',
  async (documentId: string) => {
    return pdfService.fetchPdf(PdfType.SAW_ARTICLES, documentId);
  },
);

export const fetchSubArticlesPdf = createAsyncThunk(
  'confirmed-order/pdf',
  async (documentId: string) => {
    return pdfService.fetchPdf(PdfType.SUB_ARTICLES, documentId);
  },
);

export const fetchPdfs = createAsyncThunk('pdfs', async (pdfAmounts: PDFAmount) => {
  return pdfAmounts.downloadZip
    ? pdfService.fetchPdfsZip(pdfAmounts)
    : pdfService.fetchPdfs(pdfAmounts);
});
