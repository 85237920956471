import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const selectAppState = (state: RootState) => state.app;

export const selectIsLoading = createSelector(selectAppState, appState => appState.loading);

export const selectPDFIsLoading = createSelector(selectAppState, appState => appState.pdfLoading);

export const selectPDFLoadingFailed = createSelector(
  selectAppState,
  appState => appState.pdfLoadingFailed,
);

export const selectLatestPDFSettings = createSelector(
  selectAppState,
  appState => appState.latestPDFSettings,
);

export const selectCurrentLanguage = createSelector(selectAppState, appState => appState.language);

export const selectNavbarCollapsed = createSelector(
  selectAppState,
  appState => appState.navbarCollapsed,
);

export const getSelectMode = createSelector(selectAppState, appState => appState.selectMode);
