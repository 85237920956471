import { Alteration } from './alteration.class';

export class DefaultAlterations {
  dhzr?: Alteration;
  dhzl?: Alteration;
  dzr?: Alteration;
  dzl?: Alteration;
  mms?: Alteration;
  mml?: Alteration;
  mmk?: Alteration;
  mmd?: Alteration;
  insideColorAlterations: Alteration[] = [];
}
