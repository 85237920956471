import i18next from 'i18next';
import en from 'locales/en.json';
import nl from 'locales/nl.json';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  lng: 'nl',
  fallbackLng: 'nl',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  load: 'languageOnly',
  resources: {
    nl: {
      translation: nl,
    },
    en: {
      translation: en,
    },
  },
});

export default i18next;
