import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { EdgeColor } from 'models/edge-colors/edge-color.class';

interface EdgeColorsState {
  all: PaginatedResponse<EdgeColor> | null;
  allUnpaginated: EdgeColor[];
  selected: EdgeColor | null;
  loading: boolean;
}

export const initialState: EdgeColorsState = {
  all: null,
  allUnpaginated: [],
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'edgeColors',
  initialState,
  reducers: {
    fetchEdgeColorSuccess: (state, action: PayloadAction<EdgeColor>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveEdgeColorStart: state => {
      state.loading = true;
    },
    saveEdgeColorSuccess: (state, action: PayloadAction<EdgeColor>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveEdgeColorError: state => {
      state.loading = false;
    },
    resetEdgeColorsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchEdgeColorSuccess,
  resetEdgeColorsState,
  saveEdgeColorStart,
  saveEdgeColorSuccess,
  saveEdgeColorError,
} = slice.actions;

export default slice.reducer;
