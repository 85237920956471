import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article } from 'models/articles/article.class';
import { PaginatedResponse } from 'models/common/paginated-response.interface';

interface ArticlesState {
  all: PaginatedResponse<Article> | null;
  selected: Article | null;
  loading: boolean;
}

export const initialState: ArticlesState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    fetchArticlesStart: state => {
      state.loading = true;
    },
    fetchArticlesSuccess: (state, action: PayloadAction<PaginatedResponse<Article>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchArticlesError: state => {
      state.loading = false;
    },
    fetchArticleSuccess: (state, action: PayloadAction<Article>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveArticleStart: state => {
      state.loading = true;
    },
    saveArticleSuccess: (state, action: PayloadAction<Article>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomArticleSuccess: state => {
      state.loading = false;
    },
    saveArticleError: state => {
      state.loading = false;
    },
    resetArticlesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchArticlesStart,
  fetchArticlesSuccess,
  fetchArticlesError,
  fetchArticleSuccess,
  saveArticleStart,
  saveArticleSuccess,
  saveArticleError,
  saveCustomArticleSuccess,
  resetArticlesState,
} = slice.actions;

export default slice.reducer;
