import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { ProjectArticle } from 'models/projects/project-article.class';
import { ProjectDocumentType } from 'models/projects/project-document-type.type';
import { ProjectDocument } from 'models/projects/project-document.class';
import { Project } from 'models/projects/project.class';
import ProjectsService from 'services/projects.service';
import { AppDispatch, AppThunk } from 'store';
import { setLoading, unsetLoading } from 'store/app/AppSlice';
import {
  fetchProjectSuccess,
  saveProjectError,
  saveProjectStart,
  saveProjectSuccess,
} from './ProjectsSlice';

const projectsService = new ProjectsService();

export const fetchProjects = createAsyncThunk('projects', async (params?: FetchAllParams) => {
  return projectsService.fetch(params);
});

export const fetchProject = createAsyncThunk('projects/fetchOne', async (projectId: string) => {
  return projectsService.fetchOne(projectId);
});

export const deleteProject = createAsyncThunk('projects/delete', async (projectId: string) => {
  return projectsService.delete(projectId);
});

export const moveToNewOrder = createAsyncThunk(
  'projects/move-articles',
  async (deliveryProjectArticles: ProjectArticle[]) => {
    return projectsService.moveToNewOrder(deliveryProjectArticles);
  },
);

export const copyProjectDocument = createAsyncThunk(
  'projects/copy-project-document',
  async (payload: {
    documentId: string;
    projectDocumentType: ProjectDocumentType;
    project?: ProjectDocument;
  }) => {
    const { documentId, projectDocumentType, project } = payload;

    return projectsService.copyProjectDocument(documentId, projectDocumentType, project);
  },
);

/**
 * @deprecated use regular fetchProject instead
 * @param projectId
 * @returns
 */
export const fetchProjectById = (projectId: string): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await projectsService.fetchOne(projectId);
    dispatch(fetchProjectSuccess(response));
  } catch (error) {
    dispatch(push(RoutePaths.PROJECT_DOCUMENTS));
  } finally {
    dispatch(unsetLoading());
  }
};

export const updateProject = (project: Project): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(saveProjectStart());
  try {
    const response = await projectsService.update(project.id as string, project);
    dispatch(saveProjectSuccess(response));
  } catch (error) {
    dispatch(saveProjectError());
  }
};

export const createProject = (project: Project): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(saveProjectStart());
  try {
    const response = await projectsService.create(project);
    dispatch(saveProjectSuccess(response));
  } catch (error) {
    dispatch(saveProjectError());
  }
};

/**
 * @deprecated use regular deleteProject instead
 * @param projectId
 * @returns
 */
export const deleteProjectById = (projectId: string): AppThunk => async () => {
  try {
    await projectsService.delete(projectId);
  } catch (error) {}
};

export const restoreProject = (projectId: string): AppThunk => async () => {
  try {
    await projectsService.restore(projectId);
  } catch (error) {}
};
