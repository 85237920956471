import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Login } from 'models/auth/login.class';
import { RequestPasswordReset } from 'models/auth/request-password-reset.class';
import { ChangePassword, ResetPassword } from 'models/auth/reset-password.class';
import { HttpStatusCode } from 'models/enums/http-status-code.enum';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { User } from 'models/users/user.class';
import AuthService from 'services/auth.service';
import { AppDispatch, AppThunk } from 'store';
import { apiErrorMessageToTranslationKey } from 'utils/api-error-to-translation-key';
import {
  fetchCurrentUserError,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  logOut,
  loginError,
  loginStart,
  loginSuccess,
  registerError,
  registerStart,
  registerSuccess,
  resetPasswordError,
  resetPasswordStart,
  resetPasswordSuccess,
} from './AuthSlice';

const authService = new AuthService();

export const login = (loginData: Login): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(loginStart());
  try {
    const login: Login = { ...loginData, email: loginData.email.trim() };
    const loginResponse = await authService.login(login);
    dispatch(loginSuccess(loginResponse));
    dispatch(push('/'));
  } catch (error) {
    const response = error.response as AxiosResponse;
    let errorMessage = '';
    if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
      errorMessage = response.data.message;
    }

    dispatch(loginError(errorMessage));
  }
};

export const logOutUser = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(logOut());
  dispatch(push('/'));
};

export const fetchCurrentUser = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(fetchCurrentUserStart());
  try {
    const userResponse = await authService.getCurrentUser();
    dispatch(fetchCurrentUserSuccess(userResponse));
  } catch (error) {
    const response = error.response as AxiosResponse;
    let errorMessage = '';
    if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
      errorMessage = response.data.message;
    }

    dispatch(fetchCurrentUserError(errorMessage));
  }
};

export const requestPasswordReset = async (input: RequestPasswordReset) => {
  try {
    const request: RequestPasswordReset = { email: input.email.trim() };
    await authService.requestPasswordReset(request);
    return true;
  } catch (error) {
    const response = error.response as AxiosResponse;
    let errorMessage = '';
    if (response && response.status === HttpStatusCode.UNAUTHORIZED) {
      errorMessage = response.data.message;
    }

    return errorMessage ? apiErrorMessageToTranslationKey(errorMessage) : false;
  }
};

export const resetPassword = (input: ResetPassword, isCreate = false): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(resetPasswordStart());
  try {
    if (isCreate) {
      await authService.createPassword(input);
    } else {
      await authService.resetPassword(input);
    }

    dispatch(resetPasswordSuccess());
    dispatch(push('/'));
  } catch (error) {
    const response = error.response as AxiosResponse;
    let errorMessage = '';
    if (response && response.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      errorMessage = response.data.message;
    }

    dispatch(resetPasswordError(errorMessage));
  }
};

export const updatePassword = createAsyncThunk(
  'users/updatePassword',
  async (input: ChangePassword, { rejectWithValue }) => {
    try {
      const response = await authService.changePassword(input);
      return response;
    } catch (error) {
      const response = (error as AxiosError).response as AxiosResponse;
      let errorMessage = '';
      errorMessage = response.data.message;

      return rejectWithValue(apiErrorMessageToTranslationKey(errorMessage));
    }
  },
);

export const register = (userData: User): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(registerStart());
  try {
    const user: User = { ...userData, email: userData.email.trim() };
    await authService.register(user);
    dispatch(registerSuccess());
    dispatch(push(RoutePaths.USERS));
  } catch (error) {
    const response = error.response as AxiosResponse;
    let errorMessage = '';
    if (response && response.status === HttpStatusCode.CONFLICT) {
      errorMessage = response.data.message;
    }

    dispatch(registerError(errorMessage));
  }
};
