import { TFunction } from 'i18next';
import { RadioGroupOption } from 'models/common/radio-group-option.interface';

export enum FridgeDoor {
  DEFAULT = 'default',
  DOD = 'dod',
}

export const getFridgeDoorOptions = (translateFunction: TFunction): RadioGroupOption[] =>
  Object.values(FridgeDoor).map(option => {
    return {
      label: translateFunction(`projectArticleForm.fridgeDoors.${option}`),
      value: option,
    };
  });
