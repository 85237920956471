export enum RoutePaths {
  ALTERATIONS = '/admin/alterations',
  ARTICLES = '/admin/articles',
  CATALOGS = '/admin/catalogs',
  CATEGORIES = '/admin/categories',
  COLORS = '/admin/colors',
  CONFIRMATIONS_OVERVIEW = '/confirmations',
  DESCRIPTIONS = '/admin/descriptions',
  EDGECOLORS = '/admin/edge-colors',
  GRIPS = '/admin/grips',
  MODELS = '/admin/models',
  LOCATIONS = '/admin/locations',
  EXTERNAL_ORDERS = '/projects/externalOrders',
  OFFER_DETAIL = '/projects/offer',
  ORDER_DETAIL = '/projects/order',
  CONFIRMED_ORDER_DETAIL = '/projects/confirmedOrder',
  INVOICE_DETAIL = '/projects/invoice',
  PARAMETERS = '/admin/parameters',
  PASSWORD_CREATE = '/password-create',
  PASSWORD_RESET = '/password-reset',
  PRINT_OFFER_DETAIL = '/print-offer',
  PRINT_ORDER_DETAIL = '/print-order',
  PRINT_CONFIRMED_ORDER_DETAIL = '/print-confirmed-order',
  PRINT_EXTERNAL_ORDER_DETAIL = '/print-external-order',
  PRINT_INVOICE_DETAIL = '/print-invoices',
  PRINT_SUB_ARTICLES = '/print-sub-articles',
  PRINT_SAW_ARTICLES = '/print-saw-articles',
  PRINT_BONDING_SURFACES = '/print-bonding-surfaces',
  PRINT_TICKETS = '/print-tickets',
  PROJECT_DOCUMENTS = '/projects/documents',
  PROJECT_OVERVIEW = '/projects',
  SAW_ARTICLES = '/admin/saw-articles',
  SHOWROOM = '/showroom',
  SHOWROOM_MODELS = '/showroom/models',
  SIGN_IN = '/signin',
  SPACES = '/admin/spaces',
  SUB_ARTICLES = '/admin/sub-articles',
  USERS = '/admin/users',
  PROFILE_PAGE = '/profile',
  PROFILE_PASSWORD_RESET = '/profile/reset-password',
}
