import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Space } from 'models/spaces/space.class';

interface SpacesState {
  all: PaginatedResponse<Space> | null;
  selected: Space | null;
  loading: boolean;
}

export const initialState: SpacesState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    fetchSpacesStart: state => {
      state.loading = true;
    },
    fetchSpacesSuccess: (state, action: PayloadAction<PaginatedResponse<Space>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchSpacesError: state => {
      state.loading = false;
    },
    fetchSpaceSuccess: (state, action: PayloadAction<Space>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveSpaceStart: state => {
      state.loading = true;
    },
    saveSpaceSuccess: (state, action: PayloadAction<Space>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveSpaceError: state => {
      state.loading = false;
    },
    resetSpacesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchSpacesStart,
  fetchSpacesSuccess,
  fetchSpacesError,
  fetchSpaceSuccess,
  resetSpacesState,
  saveSpaceStart,
  saveSpaceSuccess,
  saveSpaceError,
} = slice.actions;

export default slice.reducer;
