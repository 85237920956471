import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Location } from 'models/locations/location.class';

interface LocationsState {
  all: PaginatedResponse<Location> | null;
  selected: Location | null;
  loading: boolean;
}

export const initialState: LocationsState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    fetchLocationsStart: state => {
      state.loading = true;
    },
    fetchLocationsSuccess: (state, action: PayloadAction<PaginatedResponse<Location>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchLocationsError: state => {
      state.loading = false;
    },
    fetchLocationSuccess: (state, action: PayloadAction<Location>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveLocationStart: state => {
      state.loading = true;
    },
    saveLocationSuccess: (state, action: PayloadAction<Location>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveLocationError: state => {
      state.loading = false;
    },
    resetLocationsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationsError,
  fetchLocationSuccess,
  resetLocationsState,
  saveLocationStart,
  saveLocationSuccess,
  saveLocationError,
} = slice.actions;

export default slice.reducer;
