import classNames from 'classnames';
import AppNavbar from 'components/AppNavbar';
import ShowroomNavbar from 'components/ShowroomNavbar';
import Spinner from 'components/Spinner';
import { ConnectedRouter } from 'connected-react-router';
import { RoutePaths } from 'models/enums/route-paths.enum';
import PageNotFound from 'pages/PageNotFound';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import ROUTES from 'routes';
import { history } from 'store';
import { fetchCurrentUser } from 'store/auth/AuthActions';
import { selectAuthenticatedUser, selectIsLoggedIn } from 'store/auth/AuthSelectors';

const PublicRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(selectIsLoggedIn);

  return (
    <Route
      {...rest}
      render={() => (!isAuthenticated ? children : <Redirect to={{ pathname: '/' }} />)}
    />
  );
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(selectIsLoggedIn);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ pathname: RoutePaths.SIGN_IN, state: { from: location } }} />
        )
      }
    />
  );
};

const MainContentWrapper: React.FC<{ isShowroom?: boolean; withoutSpaces?: boolean }> = ({
  children,
  isShowroom,
  withoutSpaces,
}) => (
  <div className={classNames('main-content', { 'showroom-main': isShowroom })}>
    <div className={`${withoutSpaces ? '' : 'container-fluid'}`}>{children}</div>
  </div>
);

const AppRoutes: React.FC = ({ children }) => {
  const isAuthenticated = useSelector(selectIsLoggedIn);
  const currentUser = useSelector(selectAuthenticatedUser);

  const dispatch = useDispatch();
  const _fetchCurrentUser = () => dispatch(fetchCurrentUser());

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      _fetchCurrentUser();
    }
  }, []);

  return (
    <ConnectedRouter history={history}>
      {children}
      <Suspense fallback={<Spinner />}>
        <Switch>
          {ROUTES.map(route => {
            return route.isPublic ? (
              <PublicRoute key={route.key} path={route.path} exact={route.exact}>
                <MainContentWrapper>
                  <route.component />
                </MainContentWrapper>
              </PublicRoute>
            ) : (
              <PrivateRoute key={route.key} path={route.path} exact={route.exact}>
                {isAuthenticated &&
                  (route.path.startsWith('/showroom') ? <ShowroomNavbar /> : <AppNavbar />)}
                <MainContentWrapper
                  isShowroom={route.path.startsWith('/showroom')}
                  withoutSpaces={route.path.startsWith('/projects/documents')}>
                  <route.component />
                </MainContentWrapper>
              </PrivateRoute>
            );
          })}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

export default AppRoutes;
