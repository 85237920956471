import { unwrapResult } from '@reduxjs/toolkit';
import { PDFAmount } from 'models/pdf/pdf-amount';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import {
  setLatestPDFSettings,
  setPDFLoading,
  unsetPDFLoading,
  unsetPDFLoadingFailed,
} from 'store/app/AppSlice';
import { fetchPdfs } from 'store/pdf/PdfActions';

export const usePdfs = () => {
  const dispatch = useAppDispatch();
  const _fetchPdfs = (pdfAmount: PDFAmount) => dispatch(fetchPdfs(pdfAmount));
  const _setPdfIsLoading = () => dispatch(setPDFLoading());
  const _unsetPdfIsLoading = () => dispatch(unsetPDFLoading());
  const _setLatestPDFSettings = (pdfAmount: PDFAmount) => dispatch(setLatestPDFSettings(pdfAmount));
  const _unsetPDFLoadingFailed = () => dispatch(unsetPDFLoadingFailed());

  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const [zipBlob, setZipBlob] = useState<Blob>();
  const [documentReference, setDocumentReference] = useState<string>();

  const handleFetchPdfs = async (pdfAmount: PDFAmount) => {
    try {
      setDocumentReference(pdfAmount.projectDocumentReference);

      _unsetPDFLoadingFailed();
      _setPdfIsLoading();
      _setLatestPDFSettings(pdfAmount);

      const response = await _fetchPdfs(pdfAmount);

      if (fetchPdfs.fulfilled.match(response)) {
        const pdfBuffer = unwrapResult(response);

        if (!pdfBuffer || pdfBuffer.length === 0) return;

        const uint8Array = new Uint8Array(pdfBuffer);
        if (fetchPdfs.fulfilled.match(response)) {
          if (pdfAmount.downloadZip) {
            const pdfZipBlob = new Blob([uint8Array], { type: 'application/zip' });

            setZipBlob(pdfZipBlob);
          } else {
            const newPdfBlob = new Blob([uint8Array], { type: 'application/pdf' });

            setPdfBlob(newPdfBlob);
          }
        } else {
          window.alert('Error fetching PDFs');
        }
      }

      return response;
    } finally {
      _unsetPdfIsLoading();
    }
  };

  return {
    handleFetchPdfs,
    pdfBlob,
    zipBlob,
    documentReference,
  };
};
