import { LoginResponse } from 'models/auth/login-response.interface';
import { Login } from 'models/auth/login.class';
import { RequestPasswordReset } from 'models/auth/request-password-reset.class';
import { ChangePassword, ResetPassword } from 'models/auth/reset-password.class';
import { User } from 'models/users/user.class';
import api from './api';

export default class AuthService {
  get endpoint() {
    return '/auth';
  }

  async login(user: Login) {
    try {
      const response = await api.post<LoginResponse>(`${this.endpoint}/login`, user);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentUser() {
    try {
      const response = await api.get<User>('users/me');

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async register(user: User) {
    try {
      const response = await api.post<User>(`${this.endpoint}/register`, user);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async requestPasswordReset(input: RequestPasswordReset) {
    try {
      const response = await api.post<boolean>(`${this.endpoint}/reset-password`, input);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(input: ResetPassword) {
    const { password, token } = input;
    try {
      const response = await api.post<User>(`${this.endpoint}/reset-password/${token}`, {
        password,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(input: ChangePassword) {
    const { currentPassword, password } = input;
    try {
      const response = await api.patch<User>(`${this.endpoint}/change-password`, {
        currentPassword,
        password,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createPassword(input: ResetPassword) {
    const { token, ...rest } = input;
    try {
      const response = await api.post<User>(`${this.endpoint}/password-create/${token}`, {
        ...rest,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
