import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectInvoice } from 'models/projects/project-invoice.class';

interface ProjectInvoicesState {
  selected: ProjectInvoice | null;
  loading: boolean;
}

export const initialState: ProjectInvoicesState = {
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'projects-invoices',
  initialState,
  reducers: {
    fetchProjectInvoiceSuccess: (state, action: PayloadAction<ProjectInvoice>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectInvoiceStart: state => {
      state.loading = true;
    },
    saveProjectInvoiceSuccess: (state, action: PayloadAction<ProjectInvoice>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectInvoiceError: state => {
      state.loading = false;
    },
    resetProjectInvoicesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchProjectInvoiceSuccess,
  saveProjectInvoiceStart,
  saveProjectInvoiceSuccess,
  saveProjectInvoiceError,
  resetProjectInvoicesState,
} = slice.actions;

export default slice.reducer;
