import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Model } from 'models/models/model.class';

interface ModelsState {
  all: PaginatedResponse<Model> | null;
  selected: Model | null;
  loading: boolean;
}

export const initialState: ModelsState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    fetchModelSuccess: (state, action: PayloadAction<Model>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveModelStart: state => {
      state.loading = true;
    },
    saveModelSuccess: (state, action: PayloadAction<Model>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveModelError: state => {
      state.loading = false;
    },
    resetModelsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchModelSuccess,
  resetModelsState,
  saveModelStart,
  saveModelSuccess,
  saveModelError,
} = slice.actions;

export default slice.reducer;
