import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColorTypes } from 'models/colors/color-types';
import { Color } from 'models/colors/color.class';
import { DefaultColors } from 'models/colors/default-colors.class';
import { PaginatedResponse } from 'models/common/paginated-response.interface';

interface ColorsState {
  all: PaginatedResponse<Color> | null;
  allUnpaginated: Color[];
  aColors: PaginatedResponse<Color> | null;
  bColors: PaginatedResponse<Color> | null;
  insideColors: PaginatedResponse<Color> | null;
  gripColors: PaginatedResponse<Color> | null;
  plinthColors: PaginatedResponse<Color> | null;
  worksheetColors: PaginatedResponse<Color> | null;
  selected: Color | null;
  defaultColors: DefaultColors | null;
  loading: boolean;
}

export const initialState: ColorsState = {
  all: null,
  allUnpaginated: [],
  aColors: null,
  bColors: null,
  insideColors: null,
  gripColors: null,
  plinthColors: null,
  worksheetColors: null,
  selected: null,
  defaultColors: null,
  loading: false,
};

export const slice = createSlice({
  name: 'colors',
  initialState,
  reducers: {
    fetchColorsStart: state => {
      state.loading = true;
    },
    fetchColorsSuccess: (
      state,
      action: PayloadAction<{
        type: ColorTypes;
        result: PaginatedResponse<Color>;
      }>,
    ) => {
      switch (action.payload.type) {
        case 'aColors':
          state.aColors = action.payload.result;
          break;

        case 'bColors':
          state.bColors = action.payload.result;
          break;

        case 'insideColors':
          state.insideColors = action.payload.result;
          break;

        case 'gripColors':
          state.gripColors = action.payload.result;
          break;

        case 'plinthColors':
          state.plinthColors = action.payload.result;
          break;

        case 'worksheetColors':
          state.worksheetColors = action.payload.result;
          break;

        default:
          state.all = action.payload.result;
          break;
      }

      state.loading = false;
    },
    fetchColorsError: state => {
      state.loading = false;
    },
    fetchAllColorsStart: state => {
      state.loading = true;
    },
    fetchAllColorsSuccess: (state, action: PayloadAction<Color[]>) => {
      state.allUnpaginated = action.payload;
      state.loading = false;
    },
    fetchAllColorsError: state => {
      state.loading = false;
    },
    fetchColorSuccess: (state, action: PayloadAction<Color>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    fetchDefaultColorsSuccess: (state, action: PayloadAction<DefaultColors>) => {
      const defaultColors: DefaultColors = { ...new DefaultColors(), ...action.payload };

      state.defaultColors = defaultColors;
    },
    saveColorStart: state => {
      state.loading = true;
    },
    saveColorSuccess: (state, action: PayloadAction<Color>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveColorError: state => {
      state.loading = false;
    },
    resetColorsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchColorsStart,
  fetchColorsSuccess,
  fetchColorsError,
  fetchAllColorsStart,
  fetchAllColorsSuccess,
  fetchAllColorsError,
  fetchColorSuccess,
  fetchDefaultColorsSuccess,
  resetColorsState,
  saveColorStart,
  saveColorSuccess,
  saveColorError,
} = slice.actions;

export default slice.reducer;
