import { Filters } from 'models/common/filters.interface';

export class FetchAllParams {
  name?: string;
  sort?: string[];
  select?: string;
  populate?: string | Record<string, unknown>;
  page?: number;
  limit?: number;
  filters?: Filters;
  groupBy?: string;
  s?: string;
  includeDeleted?: boolean;
  includeItemWithId?: string;
  forceIncludeItemWithId?: string;
  includeItemsWithIds?: string[];
  id?: string;
}
export class fetchAllParametersParams extends FetchAllParams {
  type?: string;
}
