import { BaseModel } from 'models/base-model.class';
import { Catalog } from 'models/catalogs/catalog.class';
import Category from 'models/categories/category.class';
import { DBFile } from 'models/common/db-file.class';
import { ArticleBody } from 'models/enums/article-body.enum';
import { ArticleShape } from 'models/enums/article-shape.enum';
import { SawArticle } from 'models/saw-articles/saw-article.class';
import { Space } from 'models/spaces/space.class';
import { SubArticle } from '../sub-articles/sub-article.class';
import { ArticleMeasurements } from './article-measurements.class';
import { LinkedSawArticle } from './linked-saw-article.class';
import { LinkedSubArticle } from './linked-sub-article.class';

export class Article extends BaseModel {
  reference = '';
  description = '';
  help?: string;
  catalogs: Catalog[] = [];
  processor = '';
  supplier = '';
  shape = ArticleShape.RK;
  closetType = '';
  body: ArticleBody | '' = '';
  workTime = 0;
  machineTime = 0;
  images: DBFile[] | null = null;
  techfiles: DBFile[] | null = null;
  possibleColorsGroups?: string[];
  swingDirection = false;
  widthComputable = false;
  heightComputable = false;
  modelDependant = false;
  sawList = false;
  containsGlass = false;
  complexShape = false;
  fridge = false;
  finishing = '';
  grooved = false;
  doubleSided = false;
  basicCorpus = '';
  liningCorpus = '';
  liningB = '';
  defaultSize = 0;
  programNumber = '';
  applicable = false;
  subArticles: SubArticle[] = [];
  sawArticles: SawArticle[] = [];
  measurements: ArticleMeasurements[] = [];
  spaces?: Space[] = [];
  categories?: Category[] = [];
  isCustom = false;
  linkedSawArticles: LinkedSawArticle[] = [];
  linkedSubArticles: LinkedSubArticle[] = [];
  relatedArticles?: Article[] = [];
  fixedCosts = 0;
  price = 0;
  priceUnconverted = 0;
  priceType = '';
  productGroup?: number;

  /**
   *
   * @param reference article reference
   * @param catalogId catalog to link article with
   * @returns custom Article with default custom properties
   */
  // static createCustom(reference: string, catalogId: string): Article {
  static createCustom(reference: string, catalog: Catalog): Article {
    return {
      ...new Article(),
      body: ArticleBody.Other,
      isCustom: true,
      reference,
      catalogs: [catalog],
    };
  }
}
