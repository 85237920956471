import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Project } from 'models/projects/project.class';

interface ProjectsState {
  all: PaginatedResponse<Project> | null;
  selected: Project | null;
  loading: boolean;
}

export const initialState: ProjectsState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    fetchProjectsError: state => {
      state.loading = false;
    },
    fetchProjectSuccess: (state, action: PayloadAction<Project>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectStart: state => {
      state.loading = true;
    },
    saveProjectSuccess: (state, action: PayloadAction<Project>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectError: state => {
      state.loading = false;
    },
    resetProjectsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchProjectsError,
  fetchProjectSuccess,
  saveProjectStart,
  saveProjectSuccess,
  saveProjectError,
  resetProjectsState,
} = slice.actions;

export default slice.reducer;
