import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { UserRole } from 'models/enums/user-role.enum';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { AlignDirection } from 'react-bootstrap/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectAuthenticatedUser } from 'store/auth/AuthSelectors';
import { logOut } from 'store/auth/AuthSlice';

interface IUserMenu {
  align?: AlignDirection;
}

const UserMenu: React.FC<IUserMenu> = ({ align }) => {
  const { t } = useTranslation();

  const currentUser = useSelector(selectAuthenticatedUser);

  const dispatch = useDispatch();
  const _logOut = () => dispatch(logOut());

  return (
    <Dropdown className="user-menu mr-4">
      <Dropdown.Toggle>
        <FontAwesomeIcon icon={['far', 'user-large']} />
      </Dropdown.Toggle>

      <Dropdown.Menu align={{ lg: align || 'right' }}>
        {currentUser && [UserRole.ADMIN, UserRole.USER].includes(currentUser?.role) && (
          <Dropdown.Item as={NavLink} to={RoutePaths.PROJECT_DOCUMENTS}>
            {t('showroom.userMenu.adminPanel')}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={_logOut}>{t('navbar.signOut')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserMenu;
