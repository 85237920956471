import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FrakoContext } from 'components/App/App';
import AppLogo from 'components/AppLogo';
import { useViewport, Viewport } from 'hooks/use-viewport';
import { NavbarItem } from 'models/common/navbar-item.interface';
import { RoutePaths } from 'models/enums/route-paths.enum';
import { TestIds } from 'models/enums/test-ids.enum';
import { UserRole } from 'models/enums/user-role.enum';
import React, { useContext, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { history } from 'store';
import {
  selectLatestPDFSettings,
  selectNavbarCollapsed,
  selectPDFIsLoading,
  selectPDFLoadingFailed,
} from 'store/app/AppSelectors';
import { setNavbarCollapsed } from 'store/app/AppSlice';
import { selectAuthenticatedUser } from 'store/auth/AuthSelectors';
import { logOut } from 'store/auth/AuthSlice';
import { FrakoEvents } from 'utils/frako-events.enum';
import AppNavbarItem from './AppNavbarItem';

const AppNavbar: React.FC = () => {
  const { emitter } = useContext(FrakoContext);

  const currentUser = useSelector(selectAuthenticatedUser);
  const navbarCollapsed = useSelector(selectNavbarCollapsed);
  const pdfIsLoading = useSelector(selectPDFIsLoading);
  const pdfLoadingFailed = useSelector(selectPDFLoadingFailed);
  const latestPDFSettings = useSelector(selectLatestPDFSettings);

  const dispatch = useDispatch();
  const _logOut = () => dispatch(logOut());
  const _setNavbarCollapsed = (collapsed: boolean) => dispatch(setNavbarCollapsed(collapsed));

  const [navbarItems, setNavbarItems] = useState<NavbarItem[]>([]);

  const { t } = useTranslation();
  const { viewport } = useViewport();
  const { pathname } = useLocation();

  const userName = navbarCollapsed
    ? `${currentUser?.firstName.charAt(0).toUpperCase()}${currentUser?.lastName
        .charAt(0)
        .toUpperCase()}`
    : `${currentUser?.firstName} ${currentUser?.lastName}`;

  const userNavbarItems: NavbarItem[] = [
    {
      title: t('navbar.confirmations.title'),
      children: [
        {
          title: t('navbar.confirmations.orders'),
          icon: ['far', 'th-list'],
          to: RoutePaths.CONFIRMATIONS_OVERVIEW,
        },
      ],
    },
    {
      title: t('navbar.projects.title'),
      to: RoutePaths.PROJECT_DOCUMENTS,
    },
  ];

  const adminNavbarItem: NavbarItem = {
    title: t('navbar.admin.title'),
    children: [
      {
        title: t('navbar.admin.users'),
        icon: ['far', 'users'],
        to: RoutePaths.USERS,
      },
      {
        title: t('navbar.admin.articles'),
        icon: ['far', 'pencil-ruler'],
        to: RoutePaths.ARTICLES,
      },
      {
        title: t('navbar.admin.subArticles'),
        icon: ['far', 'cubes'],
        to: RoutePaths.SUB_ARTICLES,
      },
      {
        title: t('navbar.admin.alterations'),
        icon: ['far', 'sliders-h'],
        to: RoutePaths.ALTERATIONS,
      },
      {
        title: t('navbar.admin.sawArticles'),
        icon: ['far', 'crop'],
        to: RoutePaths.SAW_ARTICLES,
      },
      {
        title: t('navbar.admin.locations'),
        icon: ['far', 'map-marked-alt'],
        to: RoutePaths.LOCATIONS,
      },
      {
        title: t('navbar.admin.descriptions'),
        icon: ['far', 'comment-lines'],
        to: RoutePaths.DESCRIPTIONS,
      },
      {
        title: t('navbar.admin.grips'),
        icon: ['far', 'cabinet-filing'],
        to: RoutePaths.GRIPS,
      },
      {
        title: t('navbar.admin.colors'),
        icon: ['far', 'palette'],
        to: RoutePaths.COLORS,
      },
      {
        title: t('navbar.admin.edgeColors'),
        icon: ['far', 'swatchbook'],
        to: RoutePaths.EDGECOLORS,
      },
      {
        title: t('navbar.admin.models'),
        icon: ['far', 'layer-group'],
        to: RoutePaths.MODELS,
      },
      {
        title: t('navbar.admin.catalogs'),
        icon: ['far', 'book-open'],
        to: RoutePaths.CATALOGS,
      },
      {
        title: t('navbar.admin.categories'),
        icon: ['far', 'sitemap'],
        to: RoutePaths.CATEGORIES,
      },
      {
        title: t('navbar.admin.spaces'),
        icon: ['far', 'table-cells-large'],
        to: RoutePaths.SPACES,
      },
    ],
  };

  const handleToggleItemCollapse = (item: NavbarItem) => {
    setNavbarItems([
      ...navbarItems.map(navItem => {
        if (navItem.title === item.title) {
          navItem.expanded = !navItem.expanded;
        } else {
          navItem.expanded = false;
        }

        return navItem;
      }),
    ]);
  };

  useEffect(() => {
    if (viewport.device !== Viewport.DESKTOP) {
      _setNavbarCollapsed(false);
    }
  }, [viewport.device]);

  useEffect(() => {
    let navItems = [...userNavbarItems];

    if (currentUser?.role === UserRole.ADMIN) {
      navItems = [...navItems, adminNavbarItem];
    }

    navItems.forEach(item => {
      item.expanded = childRouteIsActive(item, pathname);

      return item;
    });

    setNavbarItems(navItems);
  }, [currentUser?.role]);

  const pdfLoadingStyle = pdfLoadingFailed ? { color: '#a90808', cursor: 'pointer' } : {};

  const reloadPdfFile = () => {
    emitter.emit(FrakoEvents.GET_PDFS, latestPDFSettings);
  };

  const navigateToProfilePage = () => {
    if (!currentUser) return;

    history.push({
      pathname: `${RoutePaths.USERS}/${currentUser.id}`,
    });
  };

  return (
    <Navbar
      expand="lg"
      variant="light"
      className="navbar-vertical fixed-left"
      data-testid={TestIds.APP_NAVBAR}>
      <div className="container-fluid">
        <Navbar.Toggle className="navbar-main-toggle" aria-controls="appNavbarCollapse" />

        <AppLogo className="navbar-logo" />

        <Navbar.Collapse id="appNavbarCollapse">
          <Nav as="ul">
            {navbarItems.map(item => {
              return (
                <AppNavbarItem
                  item={item}
                  key={item.title}
                  navbarCollapsed={navbarCollapsed}
                  onToggleItemCollapse={() => handleToggleItemCollapse(item)}
                />
              );
            })}
          </Nav>

          <div className="navbar-footer mt-auto pb-0 pb-lg-3 text-center flex-column">
            {(pdfIsLoading || pdfLoadingFailed) && (
              <div>
                <span
                  className="navbar-footer-redirect"
                  onClick={pdfLoadingFailed ? reloadPdfFile : undefined}>
                  <FontAwesomeIcon
                    icon={'print'}
                    className={classNames({ 'ml-2 ': !navbarCollapsed })}
                    style={pdfLoadingStyle}
                    beatFade={pdfIsLoading}
                  />

                  <h4 className="ml-3" style={pdfLoadingStyle}>
                    {pdfLoadingFailed ? t('navbar.pdfLoadingFailed') : t('navbar.pdfIsLoading')}
                  </h4>
                </span>
              </div>
            )}
            <hr className="navbar-divider my-3" />
            <div className="navbar-footer-redirect">
              <NavLink to={RoutePaths.SHOWROOM}>
                {!navbarCollapsed && t('navbar.goToShowroom')}
                <FontAwesomeIcon
                  icon={'arrow-right'}
                  className={classNames({ 'ml-2': !navbarCollapsed })}
                />
              </NavLink>
            </div>
            <hr className="navbar-divider my-3" />
            <div className="navbar-footer-buttons">
              <h4 className="p-0 m-0 text-left">
                <span data-testid={TestIds.APP_NAVBAR_USERNAME}>{userName}</span>
              </h4>
              <div className="navbar-footer-controls">
                <button className="btn btn-text btn-sm px-0" onClick={navigateToProfilePage}>
                  {!navbarCollapsed && t('navbar.myAccount')}
                </button>
                <button
                  className="btn btn-text btn-sm"
                  onClick={() => _logOut()}
                  data-testid={TestIds.APP_NAVBAR_LOGOUT_BTN}>
                  {!navbarCollapsed && t('navbar.signOut')}
                </button>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

const childRouteIsActive = (parentItem: NavbarItem, pathname: string) => {
  const result = parentItem.children?.find(child => child.to && child.to === pathname);

  return !!result;
};

export default AppNavbar;
