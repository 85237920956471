import { TestIds } from 'models/enums/test-ids.enum';
import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div className="spinner-overlay">
      <div className="spinner-wrapper">
        <div className="spinner" data-testid={TestIds.SPINNER}>
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
