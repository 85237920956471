import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const selectAuthState = (state: RootState) => state.auth;

export const selectAuthenticatedUser = createSelector(
  selectAuthState,
  authState => authState.authenticatedUser,
);

export const selectUserToken = createSelector(selectAuthState, authState => authState.token);

export const selectIsLoggedIn = createSelector(selectAuthState, authState => !!authState.token);

export const selectAuthIsLoading = createSelector(selectAuthState, authState => authState.loading);

export const selectAuthError = createSelector(selectAuthState, authState => authState.error);
