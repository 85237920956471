import { ArticleMeasurements } from 'models/articles/article-measurements.class';
import { Article } from 'models/articles/article.class';
import { BaseModel } from 'models/base-model.class';
import { Color } from 'models/colors/color.class';
import { VatRate } from 'models/enums/vat-rate.enum';
import { v4 as uuidv4 } from 'uuid';
import { FridgeDoor } from './fridge-door.enum';
import { ProjectCatalog } from './project-catalog.class';
import { SwingDirection } from './swing-direction.enum';
import { ProjectAlteration } from './tailor-article/project-alteration.class';
import { ProjectSawArticle } from './tailor-article/project-saw-article.class';
import { ProjectSubArticle } from './tailor-article/project-sub-article.class';
import { VisibleSideAllOptions } from './visible-side.enum';

export class ProjectArticleNoHelperParams extends BaseModel {
  constructor(projectArticleDto?: Partial<ProjectArticleNoHelperParams>) {
    super();

    if (projectArticleDto) {
      const projectArticleDtoCopy = { ...projectArticleDto };

      projectArticleDtoCopy &&
        helperPropertyPropertyNames.forEach(propertyName => {
          delete projectArticleDtoCopy[propertyName as keyof ProjectArticleNoHelperParams];
        });

      Object.assign(this, projectArticleDtoCopy);
    }
  }

  projectCatalog?: ProjectCatalog;
  positionNumber = 0;
  listId: string = uuidv4();
  article: Article = new Article();
  width?: number;
  height?: number;
  depth?: number;
  swingDirection?: SwingDirection;
  visibleSide?: VisibleSideAllOptions;
  aColor?: Color;
  bColor?: Color;
  gripListRecess: string[] = [];
  description = '';
  MMS = 0;
  MML = 0;
  MMK = 0;
  MMD = 0;
  legHeightDelta = 0;
  vatRate?: VatRate;
  amount = 1;
  price = 0;
  priceUnconverted = 0;
  priceEdited = false;
  total = 0;
  sawList?: boolean;
  remarks: string[] = [];
  measurement?: ArticleMeasurements;
  imported?: boolean;
  projectSawArticles: ProjectSawArticle[] = [];
  projectSubArticles: ProjectSubArticle[] = [];
  projectAlterations: ProjectAlteration[] = [];
  fridgeDoor: FridgeDoor = FridgeDoor.DEFAULT;
  externalOrderArticle?: any;
  recyclingFee?: number;
  isExternalDeliveryOrderArticle?: boolean;
}

export class ProjectArticle extends ProjectArticleNoHelperParams {
  // Helper properties
  visibleSideArray?: string[];
  recyclingFeeOption?: string;
  newRemark?: string;
}

const helperPropertyPropertyNames = ['visibleSideArray', 'recyclingFeeOption', 'newRemark'];
