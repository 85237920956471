import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { DefaultSawArticles } from 'models/saw-articles/default-saw-articles.class';
import { SawArticle } from 'models/saw-articles/saw-article.class';

interface SawArticlesState {
  all: PaginatedResponse<SawArticle> | null;
  selected: SawArticle | null;
  loading: boolean;
  fridgeDoors: SawArticle[] | null;
  defaultSawArticles: DefaultSawArticles | null;
}

export const initialState: SawArticlesState = {
  all: null,
  selected: null,
  loading: false,
  fridgeDoors: null,
  defaultSawArticles: null,
};

export const slice = createSlice({
  name: 'sawArticles',
  initialState,
  reducers: {
    fetchSawArticlesStart: state => {
      state.loading = true;
    },
    fetchSawArticlesSuccess: (state, action: PayloadAction<PaginatedResponse<SawArticle>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchDefaultSawArticlesSuccess: (state, action: PayloadAction<DefaultSawArticles>): void => {
      const defaultSawArticles: DefaultSawArticles = {
        ...new DefaultSawArticles(),
        ...action.payload,
      };
      state.defaultSawArticles = defaultSawArticles;
    },
    fetchSawArticlesError: state => {
      state.loading = false;
    },
    fetchSawArticleSuccess: (state, action: PayloadAction<SawArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    fetchFridgeDoorsSuccess: (state, action: PayloadAction<SawArticle[]>) => {
      state.fridgeDoors = action.payload;
    },
    saveSawArticleStart: state => {
      state.loading = true;
    },
    saveSawArticleSuccess: (state, action: PayloadAction<SawArticle>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomSawArticleSuccess: state => {
      state.loading = false;
    },
    saveSawArticleError: state => {
      state.loading = false;
    },
    resetSawArticlesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});
export const {
  fetchSawArticlesStart,
  fetchSawArticlesSuccess,
  fetchSawArticlesError,
  fetchSawArticleSuccess,
  fetchFridgeDoorsSuccess,
  saveSawArticleStart,
  saveSawArticleSuccess,
  saveSawArticleError,
  saveCustomSawArticleSuccess,
  resetSawArticlesState,
  fetchDefaultSawArticlesSuccess,
} = slice.actions;

export default slice.reducer;
