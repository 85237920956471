export interface PaginationMeta {
  itemCount: number;
  totalItems: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
  categories?: Facet[];
  spaces?: Facet[];
  heightBoundaries?: { min: 0; max: 0 };
  distinctWidths?: Facet[];
  distinctHeights?: Facet[];
  articleHeightBoundaries?: { min: 0; max: 0 };
  hasGrip?: Facet[];
}

export interface PaginationLinks {
  first?: string;
  previous?: string;
  next?: string;
  last?: string;
}

export class PaginatedResponse<T> {
  meta?: PaginationMeta;
  links?: PaginationLinks;
  items: T[] = [];
}

export class CursorPaginatedResponse<T> {
  cursor: ResponseCursor = new ResponseCursor();
  data: T[] = [];
}

export class ResponseCursor {
  afterCursor: string | null = null;
  beforeCursor: string | null = null;
}

export interface ModulusPaginationMeta extends PaginationMeta {
  modulus: number;
}

export class ModulusPaginatedResponse<T> {
  meta?: ModulusPaginationMeta;
  links?: PaginationLinks;
  items: T[] = [];
}

export interface Facet {
  count: number;
  facetValue: string;
  facetId: string;
}
