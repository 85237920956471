import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastOptions } from 'models/common/toast-options.interface';
import { LocalStorageKeys } from 'models/enums/local-storage-keys.enum';
import { PDFAmount } from 'models/pdf/pdf-amount';
import { toast } from 'react-toastify';
import { toastOptionsToContent } from 'utils/toast-options-to-content';

interface AppState {
  loading: boolean;
  pdfLoading: boolean;
  pdfLoadingFailed: boolean;
  excelLoading: boolean;
  excelLoadingFailed: boolean;
  // latest excel settings?
  latestPDFSettings: PDFAmount | null;
  language: string;
  navbarCollapsed: boolean;
  selectMode: boolean;
}

export const initialState: AppState = {
  loading: false,
  pdfLoading: false,
  pdfLoadingFailed: false,
  excelLoading: false,
  excelLoadingFailed: false,
  latestPDFSettings: null,
  language: localStorage.getItem(LocalStorageKeys.LANGUAGE) || 'nl',
  navbarCollapsed: JSON.parse(localStorage.getItem(LocalStorageKeys.NAVBAR_COLLAPSED) || 'false'),
  selectMode: false,
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true;
    },
    unsetLoading: state => {
      state.loading = false;
    },
    setPDFLoading: state => {
      state.pdfLoading = true;
    },
    setPDFLoadingFailed: state => {
      state.pdfLoadingFailed = true;
    },
    unsetPDFLoadingFailed: state => {
      state.pdfLoadingFailed = false;
    },
    unsetPDFLoading: state => {
      state.pdfLoading = false;
    },
    setExcelLoading: state => {
      state.excelLoading = true;
    },
    unsetExcelLoading: state => {
      state.excelLoading = false;
    },
    setExcelLoadingFailed: state => {
      state.excelLoadingFailed = true;
    },
    unsetExcelLoadingFailed: state => {
      state.excelLoadingFailed = false;
    },
    setLatestPDFSettings: (state, action: PayloadAction<PDFAmount>) => {
      state.latestPDFSettings = action.payload;
    },
    unsetLatestPDFSettings: state => {
      state.latestPDFSettings = null;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      const selectedLanguage = action.payload.substr(0, 2); // Gets only the language (ex.: en-US / en-GB = en)
      state.language = selectedLanguage;
      localStorage.setItem(LocalStorageKeys.LANGUAGE, selectedLanguage);
    },
    addToast: (state, action: PayloadAction<ToastOptions>) => {
      toast(toastOptionsToContent(action.payload), {
        type: action.payload.type,
        toastId: action.payload.toastId,
      });
    },
    setNavbarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.navbarCollapsed = action.payload;
      const navbarCollapsed = JSON.stringify(action.payload);
      localStorage.setItem(LocalStorageKeys.NAVBAR_COLLAPSED, navbarCollapsed);
    },
    setSelectMode: (state, action: PayloadAction<boolean>) => {
      state.selectMode = action.payload;
    },
  },
});

export const {
  setLoading,
  unsetLoading,
  setPDFLoading,
  unsetPDFLoading,
  setPDFLoadingFailed,
  unsetPDFLoadingFailed,
  setExcelLoading,
  unsetExcelLoading,
  setExcelLoadingFailed,
  unsetExcelLoadingFailed,
  setLatestPDFSettings,
  unsetLatestPDFSettings,
  setLanguage,
  addToast,
  setNavbarCollapsed,
  setSelectMode,
} = slice.actions;

export default slice.reducer;
