import { AxiosRequestConfig } from 'axios';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { FilterParams } from 'models/common/filter-params.interface';
import { isBlank } from './is-blank';

export function generateRequestConfig(params?: FetchAllParams): AxiosRequestConfig {
  const config: AxiosRequestConfig = {};

  if (params) {
    const { filters, ...rest } = params;
    const filterParams: FilterParams = {};

    if (filters) {
      const entries = Object.entries(filters);

      for (const [field, filter] of entries) {
        if (filter && !isBlank(filter.value)) {
          if (Array.isArray(filter.value)) {
            filterParams[field] = filter.value.map(
              (valueItem: unknown) => `${field}::${filter.operation}::${valueItem}`,
            );
          } else {
            filterParams['filters'] = `${field}::${filter.operation}::${filter.value}`;
          }
        }
      }
    }

    config.params = {
      ...rest,
      ...filterParams,
    };
  }

  return config;
}
