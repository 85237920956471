import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectOffer } from 'models/projects/project-offer.class';

interface ProjectOffersState {
  selected: ProjectOffer | null;
  loading: boolean;
}

export const initialState: ProjectOffersState = {
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'projects-offers',
  initialState,
  reducers: {
    fetchProjectOfferSuccess: (state, action: PayloadAction<ProjectOffer>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    copyProjectOfferStart: state => {
      state.loading = true;
    },
    copyProjectOfferSuccess: (state, action: PayloadAction<ProjectOffer>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    copyProjectOfferError: state => {
      state.loading = false;
    },
    saveProjectOfferStart: state => {
      state.loading = true;
    },
    saveProjectOfferSuccess: (state, action: PayloadAction<ProjectOffer>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectOfferError: state => {
      state.loading = false;
    },
    resetProjectOffersState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchProjectOfferSuccess,
  copyProjectOfferStart,
  copyProjectOfferSuccess,
  copyProjectOfferError,
  saveProjectOfferStart,
  saveProjectOfferSuccess,
  saveProjectOfferError,
  resetProjectOffersState,
} = slice.actions;

export default slice.reducer;
