import { AxiosResponse } from 'axios';
import { ProjectArticle } from 'models/projects/project-article.class';
import { ProjectConfirmedOrder } from 'models/projects/project-confirmed-order.class';
import { ProjectDocumentType } from 'models/projects/project-document-type.type';
import { ProjectDocument } from 'models/projects/project-document.class';
import { ProjectOrder } from 'models/projects/project-order.class';
import { Project } from 'models/projects/project.class';
import api from './api';
import EntitiesService from './entities.service';

export default class ProjectsService extends EntitiesService<Project> {
  get endpoint() {
    return '/projects';
  }

  moveToNewOrder = async (deliveryProjectArticles: ProjectArticle[]) => {
    const response = await api.post<ProjectOrder | ProjectConfirmedOrder>(
      `${this.endpoint}/move-to-new-order`,
      deliveryProjectArticles,
    );

    return response?.data;
  };

  async copyProjectDocument(
    id: string,
    projectDocumentType: ProjectDocumentType,
    project?: ProjectDocument,
  ): Promise<ProjectDocument> {
    const response: AxiosResponse<ProjectDocument> = project
      ? await api.post<ProjectDocument>(`${this.endpoint}/copy-document/${id}`, {
          project: project,
          type: projectDocumentType,
        })
      : await api.post<ProjectDocument>(`${this.endpoint}/copy-document/${id}`, {
          type: projectDocumentType,
        });

    return response.data;
  }
}
