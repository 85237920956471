import { TFunction } from 'i18next';
import { FormSelectOption } from 'models/common/form-select-option.interface';

export enum CatalogCategory {
  FURNITURE = 'MEUBELEN',
  APPLIANCE = 'TOESTELLEN',
  MISCELLANEOUS = 'DIVERSEN',
}

export const getCatalogCategories = (translateFunction: TFunction): FormSelectOption[] =>
  Object.values(CatalogCategory).map(item => {
    return {
      label: translateFunction(`catalogs.catalogCategories.${item}`),
      value: item,
    };
  });
