import React, { useState } from 'react';
import { Accordion, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface IModelsNavbarProps {
  onRedirect: (url: string) => void;
}

interface IOptionGroup {
  buttonName: string;
  from: number;
  to: number;
}

const columnsPerRow = 4;

const ModelsNavbar: React.FC<IModelsNavbarProps> = ({ onRedirect }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modelsOptions = [
    {
      urlPath: 'prima',
      label: `${t('showroom.models.prima')}`,
    },
    {
      urlPath: 'primaLiszt',
      label: `${t('showroom.models.primaLiszt')}`,
    },
    {
      urlPath: 'primaAretino',
      label: `${t('showroom.models.primaAretino')}`,
    },
    {
      urlPath: 'kerneik',
      label: `${t('showroom.models.kerneik')}`,
    },
    {
      urlPath: 'retro',
      label: `${t('showroom.models.retro')}`,
    },
    {
      urlPath: 'chopin',
      label: `${t('showroom.models.chopin')}`,
    },
    {
      urlPath: 'azul',
      label: `${t('showroom.models.azul')}`,
    },
    {
      urlPath: 'aqua',
      label: `${t('showroom.models.aqua')}`,
    },
    {
      urlPath: 'liszt',
      label: `${t('showroom.models.liszt')}`,
    },
    {
      urlPath: 'overviewFinishes',
      label: `${t('showroom.models.overviewFinishes')}`,
    },
    {
      urlPath: 'aretino',
      label: `${t('showroom.models.aretino')}`,
    },
    {
      urlPath: 'nostalgia',
      label: `${t('showroom.models.nostalgia')}`,
    },
    {
      urlPath: 'frameDoor',
      label: `${t('showroom.models.frameDoor')}`,
    },
    {
      urlPath: 'solid',
      label: `${t('showroom.models.solid')}`,
    },
    {
      urlPath: 'bellini',
      label: `${t('showroom.models.bellini')}`,
    },
    {
      urlPath: 'gerookte',
      label: `${t('showroom.models.gerookte')}`,
    },
    {
      urlPath: 'morasEik',
      label: `${t('showroom.models.morasEik')}`,
    },
    {
      urlPath: 'klimt',
      label: `${t('showroom.models.klimt')}`,
    },
    {
      urlPath: 'vinterio',
      label: `${t('showroom.models.vinterio')}`,
    },
    {
      urlPath: 'salsa',
      label: `${t('showroom.models.salsa')}`,
    },
    {
      urlPath: 'rumba',
      label: `${t('showroom.models.rumba')}`,
    },
    {
      urlPath: 'satie',
      label: `${t('showroom.models.satie')}`,
    },
    {
      urlPath: 'debussy',
      label: `${t('showroom.models.debussy')}`,
    },
    {
      urlPath: 'munch',
      label: `${t('showroom.models.munch')}`,
    },
  ];

  const optionsGroup = [
    {
      buttonName: `${t('showroom.models.doors')}`,
      from: 0,
      to: 3,
    },
    {
      buttonName: `${t('showroom.models.coreOak')}`,
      from: 3,
      to: 5,
    },
    {
      buttonName: `${t('showroom.models.MDFDoors')}`,
      from: 5,
      to: 9,
    },
    {
      buttonName: `${t('showroom.models.veneerDoors')}`,
      from: 9,
      to: 10,
    },
    {
      buttonName: `${t('showroom.models.framedDoors')}`,
      from: 10,
      to: 13,
    },
    {
      buttonName: `${t('showroom.models.solidDoors')}`,
      from: 13,
      to: 14,
    },
    {
      buttonName: `${t('showroom.models.plasticAcrylic')}`,
      from: 14,
      to: 15,
    },
    {
      buttonName: `${t('showroom.models.longWoodVeneer')}`,
      from: 15,
      to: 19,
    },
    {
      buttonName: `${t('showroom.models.frameDoors')}`,
      from: 19,
      to: 23,
    },
    {
      buttonName: `${t('showroom.models.grainVeneer')}`,
      from: 23,
      to: 24,
    },
  ];

  const handleRedirect = () => {
    history.push(`/showroom/models`);
  };

  return (
    <Dropdown
      className="nav-link-dropdown full-screen"
      show={isOpen}
      onToggle={() => setIsOpen(!isOpen)}>
      <Dropdown.Toggle className="bottom-liner" id="dropdown-autoclose-inside">
        {/* TODO return after return Dropdown.Menu */}
        <div className="icons">
          <div className="animated-toggle"></div>
        </div>

        <p className="m-0" onClick={handleRedirect}>
          {t('showroom.navbar.models')}
        </p>
      </Dropdown.Toggle>

      {/* TODO return after getting approve from customer */}
      <Dropdown.Menu className="dropdown-menu">
        <div className="desktop nav-link-full-screen-wrapper">
          <div className="row">
            {optionsGroup.map((group: IOptionGroup, index) => {
              if (index > columnsPerRow) return;

              return (
                <div className="col-2 nav-link-column" key={index}>
                  <h5>{group.buttonName}</h5>
                  {modelsOptions.slice(group.from, group.to).map(option => (
                    <p key={option.urlPath} onClick={() => onRedirect(option.urlPath)}>
                      {option.label}
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="row">
            {optionsGroup.map((group: IOptionGroup, index) => {
              if (index < columnsPerRow) return;

              return (
                <div className="col-2 nav-link-column" key={index}>
                  <h5>{group.buttonName}</h5>
                  {modelsOptions.slice(group.from, group.to).map(option => (
                    <p key={option.urlPath} onClick={() => onRedirect(option.urlPath)}>
                      {option.label}
                    </p>
                  ))}
                </div>
              );
            })}
          </div>
        </div>

        <div className="mobile">
          <Accordion className="nav-link-accordion" defaultActiveKey="">
            {optionsGroup.map((group: IOptionGroup, index) => (
              <React.Fragment key={index}>
                <Accordion.Toggle
                  as={Button}
                  className="collapse-title"
                  variant="link"
                  eventKey={`${index}`}>
                  {group.buttonName}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${index}`}>
                  <div className="accordion-items">
                    {modelsOptions.slice(group.from, group.to).map(option => (
                      <p key={option.urlPath} onClick={() => onRedirect(option.urlPath)}>
                        {option.label}
                      </p>
                    ))}
                  </div>
                </Accordion.Collapse>
              </React.Fragment>
            ))}
          </Accordion>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ModelsNavbar;
