import { getISOWeek } from 'date-fns';
import { diff } from 'deep-object-diff';
import { TFunction } from 'i18next';
import i18next from 'lib/i18next';
import { first } from 'lodash';
import { Article } from 'models/articles/article.class';
import { CatalogCategory } from 'models/enums/catalog-category.enum';
import { ProjectArticle } from 'models/projects/project-article.class';
import { ProjectCatalog } from 'models/projects/project-catalog.class';
import { ProjectDocument } from 'models/projects/project-document.class';
import { Project } from 'models/projects/project.class';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const CORRECTION_FACTOR = 10000;

/**
 *
 * @param integerPrice Whole number value
 * @param floatingPoint floating point value (between 0 and 1)
 * @returns integerPrice * floatingPoint corrected for floating point errors
 */
export const timesFloatingPoint = (integerPrice: number, floatingPoint: number) => {
  return Math.round(
    (integerPrice * CORRECTION_FACTOR * (floatingPoint * CORRECTION_FACTOR)) /
    (CORRECTION_FACTOR * CORRECTION_FACTOR),
  );
};

export const checkForChanges = <T extends Record<string, any>>(
  originalValue: T,
  formValue: T,
): boolean => {
  const changes = difference(originalValue, formValue);

  return Object.keys(changes).length > 0;
};

/**
 *
 * @param relevantObject Object to check for keys
 * @param key Key that needs to be found
 * @returns boolean whether the object contains the key
 */
export const objectHasKey = (relevantObject: Record<string, any>, key: string): boolean => {
  return Object.keys(relevantObject).indexOf(key) >= 0;
};

/**
 * Get the difference between 2 objects but treat null and undefined as the same value
 *
 * @param object1 Object to compare against Object2
 * @param object2 Object to compare against Object1
 * @returns An object with the differences between object1 & object2 as the params or an empty object
 */
export const difference = <T extends Record<string, any>>(object1: T, object2: T) => {
  const obj1: T = JSON.parse(JSON.stringify(object1, replacer));
  const obj2: T = JSON.parse(JSON.stringify(object2, replacer));

  return diff(obj1, obj2);
};

const replacer = (key: string, value: unknown) => {
  if (value === null) {
    return undefined;
  }

  return value;
};

/**
 * Removes helper parameters from project articles (ie. visibleSideArray)
 *
 * @param projectArticles Project article or project article array
 * @returns input with helper parameters removed
 */
export const removeHelperProperties = (projectArticles: ProjectArticle | ProjectArticle[]) => {
  if (projectArticles && Array.isArray(projectArticles)) {
    const helpersRemoved = projectArticles.map(projectArt => {
      delete projectArt.visibleSideArray;

      return projectArt;
    });

    return helpersRemoved;
  } else {
    if (projectArticles) delete projectArticles?.visibleSideArray;

    return projectArticles;
  }
};

/**
 * This function adds the currently selected value to the FormInput options so when a search is done, the field will not appear empty
 *
 * @param options Options for the FormInput
 * @param originalValue (optional) The currently selected FormInput value
 * @returns The FormInput options with the currently selected value included
 */
export const getFormSelectOptions = <T extends { id?: string }>(
  options: Array<T> = [],
  originalValue?: T,
) => {
  if (!originalValue || !originalValue.id) return options;

  const incl = options.find(opt => (opt as T).id === originalValue.id);
  if (incl) return options;

  return [originalValue, ...options] as Array<T>;
};

export const getFormMultiSelectOptions = <T extends { id?: string }>(
  options: Array<T> = [],
  originalValues: Array<T> | undefined,
) => {
  if (!originalValues || !originalValues.length) {
    return options;
  }

  const incl = [];

  for (const originalValue of originalValues) {
    const inclItem = options.find(opt => (opt as T).id === originalValue.id);

    if (!inclItem) {
      incl.unshift(originalValue);
    }
  }

  return [...incl, ...options] as Array<T>;
};

export const getProjectCatalogLabel = (projCatalog: ProjectCatalog) => {
  let label = projCatalog?.catalog?.name;

  if (projCatalog?.model && projCatalog?.model?.id) {
    label = `${label} - ${projCatalog.model.name}`;
  }

  if (projCatalog?.frontColor && projCatalog?.frontColor?.id) {
    label = `${label} - ${projCatalog.frontColor.code}`;
  }

  return label;
};

export const getDateStringWeekOf = (date: Date | string, translateFunction: TFunction): string => {
  const convertedDate = new Date(date);

  const weekNumber = getISOWeek(new Date(date));

  return translateFunction('projectOverview.deliveryDateValue', {
    weekNumber: weekNumber,
    year: convertedDate.getFullYear(),
  });
};

export const getShortDateString = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const locale =
    i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nLanguage) || 'en';

  return capitalizeFirstLetter(date.toLocaleDateString(locale, options));
};

export const getShorterDateString = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };

  const locale =
    i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nLanguage) || 'en';

  return capitalizeFirstLetter(date.toLocaleDateString(locale, options));
};

export const getNumbersDateString = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    year: 'numeric',
    day: 'numeric',
  };

  const locale =
    i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nLanguage) || 'en';

  return capitalizeFirstLetter(date.toLocaleDateString(locale, options));
};

export const getDateString = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const locale =
    i18next.language || (typeof window !== 'undefined' && window.localStorage.i18nLanguage) || 'en';

  return capitalizeFirstLetter(date.toLocaleDateString(locale, options));
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeFirstLetterAndForceLowercase = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const getTomorrowsDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return tomorrow;
};

export const formatPrice = (price: number): string => {
  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
};

export const humanReadableFileSize = (bytes: number, memoryPrefixSI = true, dp = 1) => {
  const thresh = memoryPrefixSI ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = memoryPrefixSI
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const getShortenedDescription = (description?: string, maxLength?: number) => {
  let descriptionString = '';

  if (description) {
    const descriptionList = description.split(' - ');
    descriptionString = descriptionList.slice(0, 2).join(' - ');
  }

  if (maxLength && descriptionString.length > maxLength) {
    descriptionString = `${descriptionString.substring(0, maxLength)}...`;
  }

  return descriptionString;
};

export const getFilePath = (path: string | undefined) => {
  const cleanPath = path?.replace(/\\/g, '/');
  return cleanPath ? `${apiUrl}/${cleanPath}`.replace('\\', ' / ') : '';
};

export const sortOnCreatedAt = <T>(getTextProperty: (object: T) => Date) => (obj1: T, obj2: T) =>
  new Date(getTextProperty(obj1))?.getTime() - new Date(getTextProperty(obj2))?.getTime();

export const checkIsFurniture = (article: Article): boolean => {
  return first(article.catalogs)?.category === CatalogCategory.FURNITURE;
};

export const getProjectDocValue = (
  projectDocument: ProjectDocument,
  index: number,
  apiprefix: string,
) => {
  enum ApiCatalogCategory {
    FURNITURE = 'Furniture',
    APPLIANCE = 'Appliances',
    MISCELLANEOUS = 'Misc',
  }

  const apiCategory = Object.values(ApiCatalogCategory)[index];
  const apiProp = apiprefix.concat(apiCategory) as keyof ProjectDocument;

  return projectDocument[apiProp];
};

export const getProjectValue = (project: Project, index: number, apiprefix: string) => {
  enum ApiCatalogCategory {
    FURNITURE = 'Furniture',
    APPLIANCE = 'Appliances',
    MISCELLANEOUS = 'Misc',
  }

  const apiCategory = Object.values(ApiCatalogCategory)[index];
  const apiProp = apiprefix.concat(apiCategory) as keyof Project;

  return project[apiProp];
};
