import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Alteration } from 'models/alterations/alteration.class';
import { DefaultAlterations } from 'models/alterations/default-alterations.class';
import { PaginatedResponse } from 'models/common/paginated-response.interface';

interface AlterationsState {
  all: PaginatedResponse<Alteration> | null;
  selected: Alteration | null;
  defaultAlterations: DefaultAlterations | null;
  loading: boolean;
}

export const initialState: AlterationsState = {
  all: null,
  selected: null,
  defaultAlterations: null,
  loading: false,
};

export const slice = createSlice({
  name: 'alterations',
  initialState,
  reducers: {
    fetchAlterationsStart: state => {
      state.loading = true;
    },
    fetchAlterationsSuccess: (state, action: PayloadAction<PaginatedResponse<Alteration>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchDefaultAlterationsSuccess: (state, action: PayloadAction<DefaultAlterations>) => {
      const defaultAlterations: DefaultAlterations = {
        ...new DefaultAlterations(),
        ...action.payload,
      };

      state.defaultAlterations = defaultAlterations;
    },
    fetchAlterationsError: state => {
      state.loading = false;
    },
  },
});
export const {
  fetchAlterationsStart,
  fetchAlterationsSuccess,
  fetchDefaultAlterationsSuccess,
  fetchAlterationsError,
} = slice.actions;

export default slice.reducer;
