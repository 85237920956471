export enum Pages {
  Home = 'Home',
  SignIn = 'Auth/SignIn',
  PasswordResetRequest = 'Auth/PasswordResetRequest',
  PasswordReset = 'Auth/PasswordReset',
  Users = 'Admin/Users',
  UserAddEdit = 'Admin/Users/UserAddEdit',
  Articles = 'Admin/Articles',
  ArticleAddEdit = 'Admin/Articles/ArticleAddEdit',
  Locations = 'Admin/Locations',
  LocationAddEdit = 'Admin/Locations/LocationAddEdit',
  Descriptions = 'Admin/Descriptions',
  DescriptionAddEdit = 'Admin/Descriptions/DescriptionAddEdit',
  ProjectDocuments = 'Projects/ProjectDocuments',
  ProjectOverview = 'Projects/ProjectOverview',
  ConfirmationsOverview = 'Confirmations/ConfirmationsOverview',
  OfferDetail = 'Projects/OfferDetail',
  ExternalOrders = 'Projects/ExternalOrders',
  OrderDetail = 'Projects/OrderDetail',
  ConfirmedOrderDetail = 'Projects/ConfirmedOrderDetail',
  InvoiceDetail = 'Projects/InvoiceDetail',
  Grips = 'Admin/Grips',
  GripAddEdit = 'Admin/Grips/GripAddEdit',
  EdgeColors = 'Admin/EdgeColors',
  EdgeColorAddEdit = 'Admin/EdgeColors/EdgeColorAddEdit',
  Models = 'Admin/Models',
  ModelsAddEdit = 'Admin/Models/ModelAddEdit',
  Colors = 'Admin/Colors',
  ColorAddEdit = 'Admin/Colors/ColorAddEdit',
  Catalogs = 'Admin/Catalogs',
  CatalogAddEdit = 'Admin/Catalogs/CatalogAddEdit',
  Categories = 'Admin/Categories',
  CategoryAddEdit = 'Admin/Categories/CategoryAddEdit',
  Spaces = 'Admin/Spaces',
  SpaceAddEdit = 'Admin/Spaces/SpaceAddEdit',
  SubArticles = 'Admin/SubArticles',
  SubArticleAddEdit = 'Admin/SubArticles/SubArticleAddEdit',
  Alterations = 'Admin/Alterations',
  AlterationAddEdit = 'Admin/Alterations/AlterationAddEdit',
  SawArticles = 'Admin/SawArticles',
  SawArticleAddEdit = 'Admin/SawArticles/SawArticleAddEdit',
  PrintOffer = 'Print/PrintOffer',
  PrintOrder = 'Print/PrintOrder',
  PrintConfirmedOrder = 'Print/PrintConfirmedOrder',
  PrintSubArticles = 'Print/PrintSubArticles',
  PrintSawArticles = 'Print/PrintSawArticles',
  PrintTickets = 'Print/PrintTickets',
  PrintBondingSurfaces = 'Print/PrintBondingSurfaces',
  Showroom = 'Showroom/Articles',
  ShowroomModels = 'Showroom/Models',
  ShowroomArticleDetails = 'Showroom/Articles/ShowroomArticleDetails',
  ShowroomModelDetails = 'Showroom/Models/ShowroomModelDetails',
  ProfilePage = 'Profile',
  ProfilePasswordReset = 'Profile/ProfilePasswordReset',
}
