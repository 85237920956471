import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { User } from 'models/users/user.class';

interface UsersState {
  all: PaginatedResponse<User> | null;
  allUnpaginated: User[];
  selected: User | null;
  loading: boolean;
}

export const initialState: UsersState = {
  all: null,
  allUnpaginated: [],
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersStart: state => {
      state.loading = true;
    },
    fetchUsersSuccess: (state, action: PayloadAction<PaginatedResponse<User>>) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchUsersError: state => {
      state.loading = false;
    },
    fetchAllUsersStart: state => {
      state.loading = true;
    },
    fetchAllUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.allUnpaginated = action.payload;
      state.loading = false;
    },
    fetchAllUsersError: state => {
      state.loading = false;
    },
    fetchUserSuccess: (state, action: PayloadAction<User>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    updateUserStart: state => {
      state.loading = true;
    },
    updateUserSuccess: (state, action: PayloadAction<User>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    updateUserError: state => {
      state.loading = false;
    },
    resetUsersState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersError,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  fetchAllUsersError,
  fetchUserSuccess,
  updateUserStart,
  updateUserSuccess,
  updateUserError,
  resetUsersState,
} = slice.actions;

export default slice.reducer;
