import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastOptions } from 'models/common/toast-options.interface';
import React from 'react';

export function toastOptionsToContent(options: ToastOptions) {
  let iconName: IconProp = 'info';

  switch (options.type) {
    case 'info':
      iconName = 'info-circle';
      break;
    case 'error':
      iconName = 'times-circle';
      break;
    case 'warning':
      iconName = 'exclamation-circle';
      break;
    case 'success':
      iconName = 'check-circle';
      break;
    default:
      iconName = 'info-square';
      break;
  }

  return (
    <React.Fragment>
      <FontAwesomeIcon icon={iconName} className="mr-3" />
      {options.content}
    </React.Fragment>
  );
}
