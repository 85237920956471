import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Category from 'models/categories/category.class';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import CategoriesService from 'services/categories.service';
import { AppThunk } from 'store';

const categoriesService = new CategoriesService();

export const fetchCategories = createAsyncThunk('categories', async (params?: FetchAllParams) => {
  return categoriesService.fetch(params);
});

export const fetchAllCategories = createAsyncThunk('categories/all', async () => {
  return categoriesService.fetchAll();
});

export const fetchCategory = createAsyncThunk('category/fetchOne', async (categoryId: string) => {
  return categoriesService.fetchOne(categoryId);
});

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (category: Category) => {
    try {
      return categoriesService.update(category.id as string, category);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (category: Category) => {
    try {
      return categoriesService.create(category);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const createCategoryFile = createAsyncThunk(
  'categories/createCategoryFile',
  async ({ categoryId, files }: { categoryId: string; files: File[] }) => {
    try {
      return categoriesService.uploadCategoryFile(categoryId, files);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const deleteCategoryFile = createAsyncThunk(
  'categories/deleteCategoryFile',
  async ({ categoryId, fileId }: { categoryId: string; fileId: string | undefined }) => {
    try {
      return fileId && categoriesService.removeCategoryFile(categoryId, fileId);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const deleteCategory = (categoryId: string): AppThunk => async () => {
  try {
    await categoriesService.delete(categoryId);
  } catch (error) {}
};

export const restoreCategory = (categoryId: string): AppThunk => async () => {
  try {
    await categoriesService.restore(categoryId);
  } catch (error) {}
};
