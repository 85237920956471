import { setLocale } from 'yup';
import { MixedLocale, NumberLocale, StringLocale } from 'yup/lib/locale';

const customMixed: MixedLocale = {
  required: ({ path }) => ({ key: 'fieldRequired', values: { path } }),
};

const customString: StringLocale = {
  email: ({ path }) => ({ key: 'string.email', values: { path } }),
  min: ({ path, min }) => ({ key: 'string.min', values: { path, min } }),
};

const customNumber: NumberLocale = {
  integer: ({ path }) => ({ key: 'number.integer', values: { path } }),
  min: ({ path, min }) => ({ key: 'number.min', values: { path, min } }),
};

setLocale({
  mixed: customMixed,
  string: customString,
  number: customNumber,
});
