import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { Description } from 'models/descriptions/description.class';

interface DescriptionsState {
  all: PaginatedResponse<Description> | null;
  allUnpaginated: Description[];
  selected: Description | null;
  loading: boolean;
}

export const initialState: DescriptionsState = {
  all: null,
  allUnpaginated: [],
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'descriptions',
  initialState,
  reducers: {
    fetchDescriptionSuccess: (state, action: PayloadAction<Description>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveDescriptionStart: state => {
      state.loading = true;
    },
    saveDescriptionSuccess: (state, action: PayloadAction<Description>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveDescriptionError: state => {
      state.loading = false;
    },
    resetDescriptionsState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchDescriptionSuccess,
  resetDescriptionsState,
  saveDescriptionStart,
  saveDescriptionSuccess,
  saveDescriptionError,
} = slice.actions;

export default slice.reducer;
