import { SawArticle } from './saw-article.class';

export class DefaultSawArticles {
  constructor(subArticles?: Partial<DefaultSawArticles>) {
    Object.assign(this, subArticles);
  }

  travers7a?: SawArticle;
  travers13a?: SawArticle;
  bodemMp?: SawArticle;
  bodemKpmp?: SawArticle;
}
