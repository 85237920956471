import { Color } from './color.class';

export class DefaultColors {
  ppWit?: Color;
  defaultVolumeInsideColor?: Color;
  inox?: Color;
  aluminium?: Color;
  glass?: Color;
  teak?: Color;
  none?: Color;
  l127?: Color;
}
