import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { Space } from 'models/spaces/space.class';
import SpacesService from 'services/spaces.service';
import { AppThunk } from 'store';

const spacesService = new SpacesService();

export const fetchSpaces = createAsyncThunk('spaces', async (params?: FetchAllParams) => {
  return spacesService.fetch(params);
});

export const fetchAllSpaces = createAsyncThunk('spaces', async () => {
  return spacesService.fetchAll();
});

export const fetchSpace = createAsyncThunk('space/fetchOne', async (spaceId: string) => {
  return spacesService.fetchOne(spaceId);
});

export const updateSpace = createAsyncThunk('spaces/updateSpace', async (space: Space) => {
  try {
    return spacesService.update(space.id as string, space);
  } catch (e) {
    const response = (e as AxiosError).response;
    throw response?.data.message;
  }
});

export const createSpace = createAsyncThunk('spaces/createSpace', async (space: Space) => {
  try {
    return spacesService.create(space);
  } catch (e) {
    const response = (e as AxiosError).response;
    throw response?.data.message;
  }
});

export const createSpaceFile = createAsyncThunk(
  'spaces/createSpaceFile',
  async ({ spaceId, files }: { spaceId: string; files: File[] }) => {
    try {
      return spacesService.uploadSpaceFile(spaceId, files);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const deleteSpaceFile = createAsyncThunk(
  'spaces/deleteSpaceFile',
  async ({ spaceId, fileId }: { spaceId: string; fileId: string | undefined }) => {
    try {
      return fileId && spacesService.removeSpaceFile(spaceId, fileId);
    } catch (e) {
      const response = (e as AxiosError).response;
      throw response?.data.message;
    }
  },
);

export const deleteSpace = (spaceId: string): AppThunk => async () => {
  try {
    await spacesService.delete(spaceId);
  } catch (error) {}
};

export const restoreSpace = (spaceId: string): AppThunk => async () => {
  try {
    await spacesService.restore(spaceId);
  } catch (error) {}
};
