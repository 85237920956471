export enum TestIds {
  APP_CONTAINER = 'app-container',
  APP_LOGO = 'app-logo',
  SPINNER = 'spinner',
  PUBLIC_PAGE_TITLE = 'title',
  PUBLIC_PAGE_SUBTITLE = 'subtitle',
  PAGE_TITLE = 'title',
  PAGE_PRETITLE = 'pretitle',
  FORM_INPUT = 'input',
  FORM_INPUT_APPEND = 'append',
  FORM_INPUT_ERROR = 'error',
  SUBMIT_BTN = 'submit-btn',
  LOADING_ICON = 'loading-icon',
  CHECK_ICON = 'check-icon',
  AUTH_ERROR = 'auth-error',
  MAIL_SENT_ICON = 'mail-sent-icon',
  APP_NAVBAR = 'app-navbar',
  APP_NAVBAR_USERNAME = 'app-navbar-username',
  APP_NAVBAR_TOGGLE_NAVBAR_SIZE = 'app-navbar-toggle-size',
  APP_NAVBAR_ITEM_PARENT = 'app-navbar-parent-item',
  APP_NAVBAR_ITEM_CHILD = 'app-navbar-child-item',
  APP_NAVBAR_LOGOUT_BTN = 'app-navbar-logout-btn',
  TOOLBAR = 'app-toolbar',
  TOOLBAR_ITEM = 'app-toolbar-item',
  TOOLBAR_ACTION = 'app-toolbar-action',
  PAGINATION_PAGE_BTN = 'pagination-page-btn',
  PAGINATION_ELLIPSIS_ITEM = 'pagination-ellipsis-btn',
  PAGINATION_PREVIOUS_BTN = 'pagination-previous-btn',
  PAGINATION_NEXT_BTN = 'pagination-next-btn',
  SEARCHBAR_FORM = 'searchbar-form',
  SEARCHBAR = 'searchbar',
  SEARCHBAR_SHOWROOM = 'searchbar-showroom',
  SEARCHBAR_ARTICLE_FILTER = 'searchbar-article-filter',
  DATEPICKER = 'datePicker',
  ENTITY_LIST_CONTAINER = 'entity-list-container',
  ENTITY_LIST_TABLE = 'entity-list-table',
  ENTITY_LIST_LOADING_ICON = 'entity-list-loading-icon',
  ENTITY_LIST_PAGINATION_CONTAINER = 'entity-list-pagination',
  ENTITY_LIST_TABLE_HEADER = 'table-header',
  ENTITY_LIST_TABLE_CELL = 'table-cell',
  ENTITY_LIST_TABLE_ROW = 'table-row',
  ENTITY_LIST_ROW_ACTION = 'table-action',
  ENTITY_LIST_DELETED_SWITCH = 'deleted-switch',
  SELECT_CONTAINER_ID = 'select-input-container',
  SELECT_INPUT_ID = 'select-input',
  MODAL_CONTAINER = 'modal-container',
  MODAL_HEADER = 'modal-header',
  MODAL_BODY = 'modal-body',
  CANCEL_BTN = 'cancel-btn',
  DELETE_BTN = 'delete-btn',
  RESET_BTN = 'reset-btn',
  COPY_WITHIN_PROJECT = 'copy-within-project',
  COPY_TO_NEW_PROJECT = 'copy-to-new-project',
  PROJECT_DOCUMENT_BADGE = 'project-document-badge',
  PROJECT_DOCUMENTS_FILTER = 'project-documents-filter',
  CONFIRMATION_FILTER = 'confirmation-filter',
  FORM_TAGS_CONTAINER = 'tags-container',
  PROJECT_DOC_HEADER_CONTAINER = 'doc-header-container',
  PROJECT_DOC_HEADER_EDIT_BTN = 'doc-header-edit-btn',
  FORM_RADIO_GROUP_CONTAINER = 'form-radio-container',
  RADIO_MARKER_CONTAINER = 'marker-container',
  RADIO_MARKER = 'radio-marker',
  RADIO_OPTION = 'radio-option',
  FORM_CHECKBOX_BUTTONS_CONTAINER = 'form-checkbox-buttons-container',
  FORM_CHECKBOX_BUTTON = 'form-checkbox-button',
  SAW_ARTICLE_FORM = 'saw-article-form',
  PROJ_CATALOGS_TAB = 'project-catalogs-tab',
  PROJ_ARTICLES_TAB = 'project-articles-tab',
  PROJ_CATALOG_LIST = 'project-catalogs-list',
  PROJ_CATALOG_LIST_ITEM = 'project-catalog-list-item',
  PROJ_CATALOG_LIST_ADD_BTN = 'project-catalog-list-add-btn',
  PROJ_CATALOG_FORM = 'project-catalogs-form',
  PROJ_ARTICLE_LIST = 'project-article-list',
  PROJ_ARTICLE_LIST_ITEM = 'project-article-list-item',
  PROJ_ARTICLE_LIST_ADD_BTN = 'project-article-list-add-btn',
  PROJ_ARTICLE_FORM = 'project-article-form',
  PROJ_FORM_SKELETON = 'project-article-form-skeleton',
  PROJ_ARTICLE_SUB_ARTICLE_FORM = 'project-article-sub-article-form',
  PROJ_ARTICLE_TAILORED_BODY = 'project-article-tailored',
  PROJ_ARTICLE_SUB_ARTICLE = 'project-article-sub-article',
  PROJ_ARTICLE_TAILORED_SUB_ARTICLE_TAB = 'project-article-sub-article-tab',
  PROJ_ARTICLE_TAILORED_SUB_ARTICLE_LIST_ITEM = 'project-article-sub-article-list-item',
  PROJ_ARTICLE_TAILORED_SUB_ARTICLE_LIST_ADD_BTN = 'project-article-sub-article-list-add-btn',
  PROJ_ARTICLE_SAW_ARTICLE = 'project-article-saw-article',
  PROJ_ARTICLE_SAW_ARTICLE_TAB = 'project-article-saw-article-tab',
  PROJ_ARTICLE_SAW_ARTICLE_TAB_EDITING = 'project-article-saw-article-tab-editing',
  PROJ_ARTICLE_SAW_ARTICLE_ADD_BTN = 'project-article-saw-article-add-btn',
  PROJ_ARTICLE_SAW_ARTICLE_FORM = 'project-article-sub-article-form',
  PROJ_ARTICLE_ALTERATION = 'project-article-alteration',
  PROJ_ARTICLE_ALTERATION_TAB = 'project-article-alteration-tab',
  PROJ_ARTICLE_ALTERATION_ADD_BTN = 'project-article-alteration-add-btn',
  PROJ_ARTICLE_ALTERATION_FORM = 'project-article-alteration-form',
  PROJ_ARTICLE_ALTERATION_TAB_EDITING = 'project-article-alteration-tab-editing',

  PROJECT_DOCUMENT_BODY = 'project-document-body',
  CONFIRMATION_ROW = 'confirmation-collapsable-row',
  CONFIRMATION_ROW_EXPANDED = 'confirmation-collapsable-row-expanded',
  CONFIRMATION_PRINT = 'confirmation-print-button',
  SCANNED_CHECKBOX = 'scanned-checkbox',
  MOBILE_CONFIRMATION_ROW = 'mobile-confirmation-collapsable-row',
  MOBILE_ARTICLE_TABLE = 'mobile-article-table',
}
