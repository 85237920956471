export enum PdfType {
  TICKETS = 'tickets',
  SUB_ARTICLES = 'sub-articles',
  SAW_ARTICLES = 'saw-articles',
  GLUING = 'gluing',
  OFFER = 'offer',
  ORDER = 'order',
  INVOICE = 'invoice',
  CONFIRMED_ORDER = 'confirmed-order',
}
