import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { NavbarItem } from 'models/common/navbar-item.interface';
import { TestIds } from 'models/enums/test-ids.enum';
import React from 'react';
import { Collapse, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

// Props Interfaces

interface AppNavbarItemProps {
  item: NavbarItem;
  navbarCollapsed: boolean;
  onToggleItemCollapse: () => void;
}

interface NavbarParentItemProps {
  item: NavbarItem;
  collapseKey: string;
  navbarCollapsed: boolean;
  onToggleItemCollapse: () => void;
}

interface NavbarChildItemProps {
  item: NavbarItem;
  navbarCollapsed: boolean;
  onDropdownItemClick: () => void;
}

// Local Components

const NavbarParentItem: React.FC<NavbarParentItemProps> = ({
  item,
  collapseKey,
  navbarCollapsed,
  onToggleItemCollapse,
}) => {
  const { title, icon, expanded } = item;

  return navbarCollapsed ? (
    <Dropdown.Toggle
      as="a"
      className={classNames('nav-link cursor-pointer', {
        active: expanded,
      })}
      id={`${collapseKey}`}
      data-testid={TestIds.APP_NAVBAR_ITEM_PARENT}>
      {icon && (
        <span className="nav-icon">
          <FontAwesomeIcon icon={icon} className="" />
        </span>
      )}
    </Dropdown.Toggle>
  ) : (
    <a
      className="nav-link"
      role="button"
      aria-expanded={expanded}
      aria-controls={collapseKey}
      data-testid={TestIds.APP_NAVBAR_ITEM_PARENT}
      onClick={onToggleItemCollapse}>
      {icon && (
        <span className="nav-icon mr-2">
          <FontAwesomeIcon icon={icon} className="" />
        </span>
      )}
      {title}
    </a>
  );
};

const NavbarChildItem: React.FC<NavbarChildItemProps> = ({
  item,
  navbarCollapsed,
  onDropdownItemClick,
}) => {
  const { title, to, icon } = item;
  const path = to as string;

  return (
    <NavLink
      activeClassName="active"
      exact
      className={classNames({
        'nav-link': !navbarCollapsed,
        'dropdown-item': navbarCollapsed,
      })}
      to={path}
      title={title}
      data-testid={TestIds.APP_NAVBAR_ITEM_CHILD}
      onClick={navbarCollapsed ? onDropdownItemClick : undefined}>
      {icon && (
        <span className="nav-icon mr-2">
          <FontAwesomeIcon icon={icon} className="" />
        </span>
      )}
      {title}
    </NavLink>
  );
};

// Default Component

const AppNavbarItem: React.FC<AppNavbarItemProps> = ({
  item,
  navbarCollapsed,
  onToggleItemCollapse,
}) => {
  const itemKey = item.title.toLowerCase();
  const collapseKey = `collapse-${itemKey}`;

  return (
    <Dropdown as="li" drop="right" className="nav-item">
      {item.children?.length ? (
        <NavbarParentItem
          item={item}
          collapseKey={collapseKey}
          navbarCollapsed={navbarCollapsed}
          onToggleItemCollapse={onToggleItemCollapse}
        />
      ) : (
        item.to && (
          <NavbarChildItem
            item={item}
            navbarCollapsed={navbarCollapsed}
            onDropdownItemClick={onToggleItemCollapse}
          />
        )
      )}
      {item.children?.length &&
        (navbarCollapsed ? (
          <Dropdown.Menu as="ul" aria-labelledby={collapseKey} renderOnMount>
            <Dropdown.Header as="li" className="d-none d-lg-block">
              <h6 className="text-uppercase mb-0">{item.title}</h6>
            </Dropdown.Header>
            {item.children.map(child => {
              return (
                <AppNavbarItem
                  item={child}
                  key={child.title}
                  navbarCollapsed={navbarCollapsed}
                  onToggleItemCollapse={onToggleItemCollapse}
                />
              );
            })}
          </Dropdown.Menu>
        ) : (
          <Collapse in={item.expanded} data-parent="#appNavbarCollapse">
            <ul className="nav nav-sm flex-column" id={collapseKey}>
              {item.children.map(child => {
                return (
                  <AppNavbarItem
                    item={child}
                    key={child.title}
                    navbarCollapsed={navbarCollapsed}
                    onToggleItemCollapse={onToggleItemCollapse}
                  />
                );
              })}
            </ul>
          </Collapse>
        ))}
    </Dropdown>
  );
};

export default AppNavbarItem;
