import Category from 'models/categories/category.class';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IFinishesNavbarProps {
  onRedirect: (url: string) => void;
  articleCategories: Category[];
}

const FinishesNavbar: React.FC<IFinishesNavbarProps> = ({ onRedirect, articleCategories }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (articleCategories.length) {
    console.log(articleCategories[0]);
  }

  const finishesOptions = articleCategories.map(category => ({
    urlPath: category.name,
    label: category.name,
    id: category.id,
  }))

  // const finishesOptions = useMemo(
  //   () => [
  //     {
  //       urlPath: 'Paslatten',
  //       label: `${t('showroom.finishes.fittingSlats')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Paslatten';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Steunzijdes & zijpanelen',
  //       label: `${t('showroom.finishes.support')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Steunzijdes & zijpanelen';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Hoekuitvullingen',
  //       label: `${t('showroom.finishes.corner')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Hoekuitvullingen';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Leggers',
  //       label: `${t('showroom.finishes.shelves')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Leggers';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Plinten & afsluitlatten',
  //       label: `${t('showroom.finishes.skirting')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Plinten & afsluitlatten';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Lijstwerk',
  //       label: `${t('showroom.finishes.moldings')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Lijstwerk';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Lijstwerk',
  //       label: `${t('showroom.finishes.grip')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Lijstwerk';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Decorative elementen',
  //       label: `${t('showroom.finishes.decorative')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Decorative elementen';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Dampkapmantels',
  //       label: `${t('showroom.finishes.hood')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Dampkapmantels';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Plaatmateriaal',
  //       label: `${t('showroom.finishes.sheet')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Plaatmateriaal';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Deuren',
  //       label: `${t('showroom.finishes.doors')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Deuren';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Binnendeuren',
  //       label: `${t('showroom.finishes.interior')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Binnendeuren';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Kunststof werkbladen',
  //       label: `${t('showroom.finishes.plastic')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Kunststof werkbladen';
  //       })?.id,
  //     },
  //     {
  //       urlPath: 'Solid surface',
  //       label: `${t('showroom.finishes.solid')}`,
  //       id: articleCategories?.items?.find(category => {
  //         return category.name === 'Solid surface';
  //       })?.id,
  //     },
  //   ],
  //   [articleCategories],
  // );

  // const finishesOptions = [
  //   {
  //     urlPath: 'Paslatten',
  //     label: `${t('showroom.finishes.fittingSlats')}`,
  //   },
  //   {
  //     urlPath: 'Steunzijdes & zijpanelen',
  //     label: `${t('showroom.finishes.support')}`,
  //   },
  //   {
  //     urlPath: 'Hoekuitvullingen',
  //     label: `${t('showroom.finishes.corner')}`,
  //   },
  //   {
  //     urlPath: 'Leggers',
  //     label: `${t('showroom.finishes.shelves')}`,
  //   },
  //   {
  //     urlPath: 'Plinten & afsluitlatten',
  //     label: `${t('showroom.finishes.skirting')}`,
  //   },
  //   {
  //     urlPath: 'Lijstwerk',
  //     label: `${t('showroom.finishes.moldings')}`,
  //   },
  //   {
  //     urlPath: 'Lijstwerk',
  //     label: `${t('showroom.finishes.grip')}`,
  //   },
  //   {
  //     urlPath: 'Decorative elementen',
  //     label: `${t('showroom.finishes.decorative')}`,
  //   },
  //   {
  //     urlPath: 'Dampkapmantels',
  //     label: `${t('showroom.finishes.hood')}`,
  //   },
  //   {
  //     urlPath: 'Plaatmateriaal',
  //     label: `${t('showroom.finishes.sheet')}`,
  //   },
  //   {
  //     urlPath: 'Deuren',
  //     label: `${t('showroom.finishes.doors')}`,
  //   },
  //   {
  //     urlPath: 'Binnendeuren',
  //     label: `${t('showroom.finishes.interior')}`,
  //   },
  //   {
  //     urlPath: 'Kunststof werkbladen',
  //     label: `${t('showroom.finishes.plastic')}`,
  //   },
  //   {
  //     urlPath: 'Solid surface',
  //     label: `${t('showroom.finishes.solid')}`,
  //   },
  // ];

  const columnAmount = 4;
  const itemsPerColumn = Math.ceil(finishesOptions.length / columnAmount);

  const handleRedirect = (urlPath: string) => {
    onRedirect(urlPath);
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown
      className="nav-link-dropdown full-screen"
      show={isOpen}
      onToggle={() => setIsOpen(!isOpen)}>
      <Dropdown.Toggle className="bottom-liner" id="dropdown-autoclose-inside">
        <div className="icons">
          <div className="animated-toggle"></div>
        </div>

        <p className="m-0">{t('showroom.navbar.finishes')}</p>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu">
        <div className="desktop nav-link-full-screen-wrapper">
          <div className="row">
            <div className="col-3 nav-link-column">
              {finishesOptions.slice(0, itemsPerColumn).map(option => (
                <p
                  style={
                    !option.id
                      ? { color: 'lightgray', cursor: 'default', textDecoration: 'none' }
                      : {}
                  }
                  key={option.urlPath}
                  onClick={option.id ? () => handleRedirect(option.urlPath) : undefined}>
                  {option.label}
                </p>
              ))}
            </div>
            <div className="col-3 nav-link-column">
              {finishesOptions.slice(itemsPerColumn, itemsPerColumn * 2).map(option => (
                <p
                  style={
                    !option.id
                      ? { color: 'lightgray', cursor: 'default', textDecoration: 'none' }
                      : {}
                  }
                  key={option.urlPath}
                  onClick={option.id ? () => handleRedirect(option.urlPath) : undefined}>
                  {option.label}
                </p>
              ))}
            </div>
            <div className="col-3 nav-link-column">
              {finishesOptions.slice(itemsPerColumn * 2, itemsPerColumn * 3).map(option => (
                <p
                  style={
                    !option.id
                      ? { color: 'lightgray', cursor: 'default', textDecoration: 'none' }
                      : {}
                  }
                  key={option.urlPath}
                  onClick={option.id ? () => handleRedirect(option.urlPath) : undefined}>
                  {option.label}
                </p>
              ))}
            </div>
            <div className="col-3 nav-link-column">
              {finishesOptions.slice(itemsPerColumn * 3, itemsPerColumn * 4).map(option => (
                <p
                  style={
                    !option.id
                      ? { color: 'lightgray', cursor: 'default', textDecoration: 'none' }
                      : {}
                  }
                  key={option.urlPath}
                  onClick={option.id ? () => handleRedirect(option.urlPath) : undefined}>
                  {option.label}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="mobile">
          <div className="nav-link-items">
            {finishesOptions.map(option => {
              return (
                <Dropdown.Item
                  className="nav-link-item"
                  key={option.urlPath}
                  onClick={option.id ? () => handleRedirect(option.urlPath) : undefined}>
                  {option.label}
                </Dropdown.Item>
              );
            })}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FinishesNavbar;
