import { PDFAmount } from 'models/pdf/pdf-amount';
import { PdfType } from 'models/pdf/pdf-types.enum';
import api from './api';
import EntitiesService from './entities.service';

export default class PdfService extends EntitiesService<number[]> {
  get endpoint() {
    return '/pdf';
  }

  // TODO: Remove when modal is fully implemented
  async fetchPdf(pdfType: PdfType, documentId: string): Promise<number[]> {
    const response = await api.get<number[]>(`${this.endpoint}/${pdfType}/${documentId}`);

    return response?.data;
  }

  async fetchPdfs(pdfAmount: PDFAmount): Promise<number[]> {
    const response = await api.post<number[]>(`${this.endpoint}`, pdfAmount);

    return response?.data;
  }

  async fetchPdfsZip(pdfAmount: PDFAmount): Promise<number[]> {
    const response = await api.post<number[]>(`${this.endpoint}/zip`, pdfAmount);

    return response?.data;
  }
}
