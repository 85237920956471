import { useEffect, useState } from 'react';

export enum Viewport {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

const getDevice = (width: number) => {
  if (width < 768) return Viewport.MOBILE;
  else if (width < 992) return Viewport.TABLET;
  else return Viewport.DESKTOP;
};

export function useViewport() {
  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    device: getDevice(window.innerWidth),
  });

  useEffect(() => {
    const handleResize = () =>
      setViewport({
        width: window.innerWidth,
        device: getDevice(window.innerWidth),
      });
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { viewport };
}
