import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { CustomerAddress } from 'models/customer-addresses/customer-address.class';

interface CustomerAddresssState {
  all: PaginatedResponse<CustomerAddress> | null;
  selected: CustomerAddress | null;
  loading: boolean;
}

export const initialState: CustomerAddresssState = {
  all: null,
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomerAddressesStart: state => {
      state.loading = true;
    },
    fetchCustomerAddressesSuccess: (
      state,
      action: PayloadAction<PaginatedResponse<CustomerAddress>>,
    ) => {
      state.all = action.payload;
      state.loading = false;
    },
    fetchCustomerAddressesError: state => {
      state.loading = false;
    },
    fetchCustomerAddressSuccess: (state, action: PayloadAction<CustomerAddress>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomerAddressStart: state => {
      state.loading = true;
    },
    saveCustomerAddressSuccess: (state, action: PayloadAction<CustomerAddress>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveCustomerAddressError: state => {
      state.loading = false;
    },
    resetCustomerAddressesState: state => {
      state.loading = false;
      state.selected = null;
    },
  },
});

export const {
  fetchCustomerAddressesStart,
  fetchCustomerAddressesSuccess,
  fetchCustomerAddressesError,
  fetchCustomerAddressSuccess,
  resetCustomerAddressesState,
  saveCustomerAddressStart,
  saveCustomerAddressSuccess,
  saveCustomerAddressError,
} = slice.actions;

export default slice.reducer;
