import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectOrder } from 'models/projects/project-order.class';

interface ProjectOrdersState {
  selected: ProjectOrder | null;
  loading: boolean;
}

export const initialState: ProjectOrdersState = {
  selected: null,
  loading: false,
};

export const slice = createSlice({
  name: 'projects-orders',
  initialState,
  reducers: {
    fetchProjectOrderSuccess: (state, action: PayloadAction<ProjectOrder>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectOrderStart: state => {
      state.loading = true;
    },
    saveProjectOrderSuccess: (state, action: PayloadAction<ProjectOrder>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    saveProjectOrderError: state => {
      state.loading = false;
    },
    resetProjectOrdersState: state => {
      state.loading = false;
      state.selected = null;
    },
    copyProjectOfferStart: state => {
      state.loading = true;
    },
    copyProjectOfferSuccess: (state, action: PayloadAction<ProjectOrder>) => {
      state.selected = action.payload;
      state.loading = false;
    },
    copyProjectOfferError: state => {
      state.loading = false;
    },
  },
});

export const {
  fetchProjectOrderSuccess,
  saveProjectOrderStart,
  saveProjectOrderSuccess,
  saveProjectOrderError,
  resetProjectOrdersState,
  copyProjectOfferStart,
  copyProjectOfferSuccess,
  copyProjectOfferError,
} = slice.actions;

export default slice.reducer;
