import { unwrapResult } from '@reduxjs/toolkit';
import Category from 'models/categories/category.class';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { fetchAllCategories } from 'store/categories/CategoriesActions';

export const useAllCategories = () => {
  const dispatch = useAppDispatch();
  const _fetchCategories = () => dispatch(fetchAllCategories());

  const [allCategories, setAllCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const response = await _fetchCategories();
      if (response.payload) {
        setAllCategories(unwrapResult(response));
      }
      setLoading(false);
    })();
  }, []);

  return {
    allCategories,
    loading,
  };
};
