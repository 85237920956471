import { BaseModel } from 'models/base-model.class';
import { Customer } from 'models/customers/customer.class';
import { CatalogCategory } from 'models/enums/catalog-category.enum';

export class Catalog extends BaseModel {
  id = '';
  name = '';
  supplier?: Customer;
  external_id?: number;
  onTicket = false;
  article = false;
  category: CatalogCategory = CatalogCategory.FURNITURE;
  hasGrip = false;
}
