import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios from 'axios';
import { LoginResponse } from 'models/auth/login-response.interface';
import { ApiErrorMessage } from 'models/enums/api-error-message.enum';
import { User } from 'models/users/user.class';
import { apiErrorMessageToTranslationKey } from 'utils/api-error-to-translation-key';

interface AuthState {
  authenticatedUser: User | null;
  token: string;
  loading: boolean;
  error: string;
}

export const initialState: AuthState = {
  authenticatedUser: null,
  token: localStorage.getItem('token') || '',
  loading: false,
  error: '',
};

// Auth state helper functions
const cleanUserData = (state: AuthState) => {
  state.authenticatedUser = null;
  state.token = '';

  localStorage.removeItem('token');
  delete Axios.defaults.headers.common.Authorization;
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: state => {
      state.loading = true;
      state.error = '';
    },
    loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
      const { user, token } = action.payload;
      state.authenticatedUser = user;

      state.token = token;
      localStorage.setItem('token', token);

      state.loading = false;
    },
    loginError: (state, action: PayloadAction<string>) => {
      cleanUserData(state);
      if (action.payload) {
        state.error = apiErrorMessageToTranslationKey(action.payload as ApiErrorMessage);
      }
      state.loading = false;
    },
    fetchCurrentUserStart: state => {
      state.loading = true;
      state.error = '';
    },
    fetchCurrentUserSuccess: (state, action: PayloadAction<User>) => {
      state.authenticatedUser = action.payload;
      state.loading = false;
    },
    fetchCurrentUserError: (state, action: PayloadAction<string>) => {
      cleanUserData(state);
      if (action.payload) {
        state.error = apiErrorMessageToTranslationKey(action.payload as ApiErrorMessage);
      }
      state.loading = false;
    },
    logOut: state => {
      cleanUserData(state);
    },
    resetAuthState: state => {
      state.loading = false;
      state.error = '';
    },
    resetPasswordStart: state => {
      state.loading = true;
      state.error = '';
    },
    resetPasswordSuccess: state => {
      state.loading = false;
    },
    resetPasswordError: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.error = apiErrorMessageToTranslationKey(action.payload as ApiErrorMessage);
      }
      state.loading = false;
    },
    changePasswordStart: state => {
      state.loading = true;
      state.error = '';
    },
    changePasswordSuccess: state => {
      state.loading = false;
    },
    changePasswordError: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.error = apiErrorMessageToTranslationKey(action.payload as ApiErrorMessage);
      }
      state.loading = false;
    },
    registerStart: state => {
      state.loading = true;
      state.error = '';
    },
    registerSuccess: state => {
      state.loading = false;
    },
    registerError: (state, action: PayloadAction<string>) => {
      if (action.payload) {
        state.error = apiErrorMessageToTranslationKey(action.payload as ApiErrorMessage);
      }
      state.loading = false;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginError,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserError,
  logOut,
  resetAuthState,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordError,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordError,
  registerStart,
  registerSuccess,
  registerError,
} = slice.actions;

export default slice.reducer;
