import { FrakoContext } from 'components/App/App';
import AppLogo from 'components/AppLogo';
import { useAllCategories } from 'hooks-data/use-all-categories';
import { useAllSpaces } from 'hooks-data/use-all-spaces';
import { useCategories } from 'hooks-data/use-categories';
import React, { useContext } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSpaces } from '../../hooks-data/use-spaces';
import FinishesNavbar from './FinishesNavbar';
import ModelsNavbar from './ModelsNavbar';
import SpacesNavbar from './SpacesNavbar';
import UserMenu from './UserMenu';
// import AccessoriesNavbar from './AccessoriesNavbar';

const ShowroomNavbar: React.FC = () => {
  const history = useHistory();
  const { paginatedSpaces } = useSpaces();
  const { paginatedCategories: articleCategories } = useCategories();
  const { allCategories, loading: categoriesLoading } = useAllCategories();
  const { allSpaces, loading: spacesLoading } = useAllSpaces();

  const { emitter } = useContext(FrakoContext);

  const onRedirect = (path: string, key: string) => {
    if (key === 'spaces') {
      const spaceId = paginatedSpaces?.items?.find(space => space.name === path)?.id;
      const spacesPath = spaceId ? `?spaces=${spaceId}` : ``;

      history.replace(`/showroom${spacesPath}`);
    }
    if (key === 'finishes') {
      const categoryId = articleCategories?.items?.find(category => {
        return category.name === path;
      })?.id;

      const categoriesPath = categoryId ? `?categories=${categoryId}` : ``;

      history.replace(`/showroom${categoriesPath}`);
    }
  };

  return (
    <Navbar expand="lg" className="showroom-navbar">
      <div className="container-fluid py-lg-4 justify-content-lg-start">
        <div className="toggle-wrapper">
          <div className="ml-lg-auto d-block d-lg-none">
            <UserMenu align="left" />
          </div>

          <Navbar.Toggle aria-controls="appNavbarCollapse" />
        </div>

        <Navbar.Brand
          className="px-4 px-lg-5"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/showroom')
          }
        >
          <AppLogo className="text-white" />
        </Navbar.Brand>

        <Navbar.Collapse id="appNavbarCollapse" className="collapse-wrapper">
          <Nav>
            {!spacesLoading && <SpacesNavbar allSpaces={allSpaces} onRedirect={path => onRedirect(path, 'spaces')} />}
            {!categoriesLoading && <FinishesNavbar
              articleCategories={allCategories}
              onRedirect={path => onRedirect(path, 'finishes')}
            />}
            <ModelsNavbar onRedirect={path => onRedirect(path, 'models')} />
            {/*<AccessoriesNavbar onRedirect={onRedirect} />*/} {/*TODO return after having links*/}
          </Nav>
        </Navbar.Collapse>

        <div className="ml-lg-auto d-none d-lg-block">
          <UserMenu />
        </div>
      </div>
    </Navbar>
  );
};

export default ShowroomNavbar;
