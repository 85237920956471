import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectArticle } from 'models/projects/project-article.class';
import { ProjectCatalog } from 'models/projects/project-catalog.class';

interface ProjectArticlesState {
  articleList: ProjectArticle[];
  selectedProjectArticle?: ProjectArticle;
  initialSelectedProjCatalog?: ProjectCatalog;
  loading: boolean;
  busy: boolean;
  manualSave: boolean;
  selectedExternalDeliveryArticleList: ProjectArticle[];
}

export const initialState: ProjectArticlesState = {
  articleList: [],
  selectedExternalDeliveryArticleList: [],
  selectedProjectArticle: undefined,
  initialSelectedProjCatalog: undefined,
  loading: false,
  busy: false,
  manualSave: false,
};

const getSortedProjectArticleList = (projectArticleList: ProjectArticle[]): ProjectArticle[] => {
  const listCopy = [...projectArticleList];

  listCopy.sort((a, b) => a.positionNumber - b.positionNumber);

  const projectArticleUpdatedPositionNumber = listCopy.map((listValue, index) => ({
    ...listValue,
    positionNumber: index,
  }));

  return projectArticleUpdatedPositionNumber.sort((a, b) => a.positionNumber - b.positionNumber);
};

export const slice = createSlice({
  name: 'projects-articles',
  initialState,
  reducers: {
    setProjectArticlesList: (state, newArticleList: PayloadAction<ProjectArticle[]>) => {
      state.articleList = getSortedProjectArticleList(newArticleList.payload);
    },
    setSelectedProjectArticle: (state, selectedProjectArticle: PayloadAction<ProjectArticle>) => {
      state.selectedProjectArticle = selectedProjectArticle.payload;
    },
    resetSelectedProjectArticle: state => {
      state.selectedProjectArticle = undefined;
    },
    setProjectArticleListAndSelectedProjectArticle: (
      state,
      payload: PayloadAction<{
        updatedProjectArticleList: ProjectArticle[];
        newlySelectedProjectArticle: ProjectArticle;
      }>,
    ) => {
      state.articleList = getSortedProjectArticleList(payload.payload.updatedProjectArticleList);
      state.selectedProjectArticle = payload.payload.newlySelectedProjectArticle;
    },
    addNewArticleToList: (
      state,
      copiedProjectArticle: PayloadAction<ProjectArticle | undefined>,
    ) => {
      const positionNumber = state.articleList.length;
      const newProjectArticle: ProjectArticle = {
        ...new ProjectArticle(),
        ...copiedProjectArticle.payload,
        projectCatalog: state.initialSelectedProjCatalog,
        positionNumber: positionNumber,
      };

      state.articleList.push(newProjectArticle);
      state.selectedProjectArticle = newProjectArticle;
    },
    fetchProjectArticleStart: state => {
      state.loading = true;
    },
    fetchProjectArticleSuccess: (state, action: PayloadAction<ProjectArticle>) => {
      state.selectedProjectArticle = action.payload;
      state.loading = false;
    },
    fetchProjectArticleError: state => {
      state.loading = false;
    },
    setFormIsBusy: state => {
      state.busy = true;
    },
    unsetFormIsBusy: state => {
      state.busy = false;
    },
    setManualSave: state => {
      state.manualSave = true;
    },
    unsetManualSave: state => {
      state.manualSave = false;
    },
    setInitialSelectedProjectCatalog: (state, action: PayloadAction<ProjectCatalog>) => {
      state.initialSelectedProjCatalog = action.payload;
    },
    resetInitialSelectedProjectCatalog: state => {
      state.initialSelectedProjCatalog = undefined;
    },
    externalDeliveryArticleSelected: (state, action: PayloadAction<ProjectArticle>) => {
      const articleToAdd = action.payload;

      state.selectedExternalDeliveryArticleList.push(articleToAdd);
    },
    externalDeliveryArticleDeSelected: (state, action: PayloadAction<ProjectArticle>) => {
      const updatedList = state.selectedExternalDeliveryArticleList.filter(
        selectedArticle => selectedArticle.id !== action.payload.id,
      );

      state.selectedExternalDeliveryArticleList = updatedList;
    },
    resetExternalDeliveryArticles: state => {
      state.selectedExternalDeliveryArticleList = [];
    },
  },
});

export const {
  setProjectArticlesList,
  addNewArticleToList,
  setSelectedProjectArticle,
  resetSelectedProjectArticle,
  setProjectArticleListAndSelectedProjectArticle,
  fetchProjectArticleStart,
  fetchProjectArticleSuccess,
  fetchProjectArticleError,
  setFormIsBusy,
  unsetFormIsBusy,
  setManualSave,
  unsetManualSave,
  setInitialSelectedProjectCatalog,
  resetInitialSelectedProjectCatalog,
  externalDeliveryArticleSelected,
  externalDeliveryArticleDeSelected,
  resetExternalDeliveryArticles,
} = slice.actions;

export default slice.reducer;
