import { unwrapResult } from '@reduxjs/toolkit';
import Category from 'models/categories/category.class';
import { FetchAllParams } from 'models/common/fetch-all-params.interface';
import { PaginatedResponse } from 'models/common/paginated-response.interface';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { fetchCategories } from 'store/categories/CategoriesActions';

export const useCategories = (initialParams = new FetchAllParams()) => {
  const dispatch = useAppDispatch();
  const _fetchCategories = (params?: FetchAllParams) => dispatch(fetchCategories(params));
  const [params, setParams] = useState<FetchAllParams>(initialParams);

  const [paginatedCategories, setPaginatedCategories] = useState<PaginatedResponse<Category>>(
    new PaginatedResponse(),
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const response = await _fetchCategories(params);
      if (response.payload) {
        setPaginatedCategories(unwrapResult(response));
      }
      setLoading(false);
    })();
  }, [params]);

  return {
    params,
    setParams,
    paginatedCategories,
    loading,
  };
};
