import Category from 'models/categories/category.class';
import api from './api';
import EntitiesService from './entities.service';

export default class CategoriesService extends EntitiesService<Category> {
  get endpoint() {
    return '/categories';
  }

  private handleFormDataFiles(files: File[]) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('images', file);
    });
    return formData;
  }

  async uploadCategoryFile(categoryId: string, images: File[]) {
    const formData = this.handleFormDataFiles(images);

    try {
      const response = await api.post(`${this.endpoint}/picture/${categoryId}`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async removeCategoryFile(categoryId: string, fileId: string) {
    try {
      const response = await api.delete(`${this.endpoint}/picture/${categoryId}/${fileId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
