export enum FrakoEvents {
  FOCUS_ON_INVALID_ARTICLE_FIELD = 'focusOnInvalidArticleField',
  FOCUS_ON_INVALID_CATALOG_FIELD = 'focusOnInvalidCatalogField',
  COPY_PROJECT_ARTICLE = 'copyProjectArticle',
  GET_PDFS = 'getPdfs',
  GET_EXCEL_FILES = 'getExcelFiles',
  GET_EXTERNAL_ORDER_PDFS = 'getExternalOrderPdfs',
  UPDATE_PROJECT_ARTICLES_LIST = 'updateProjectArticlesList',
  PROJECT_ARTICLE_FORM_FOCUS = 'projectArticleFocus',
  PROJECT_ARTICLE_FORM_BLUR = 'projectArticleBlur',
  ALTER_PROJECT_ARTICLE_REMARKS = 'alterProjectArticleRemarks',
  REMOVE_SAW_ARTICLE_REMARK = 'addSawArticleRemark',
  REMOVE_SUB_ARTICLE_REMARK = 'removeSubArticleRemark',
  MOVE_TO_NEW_ORDER = 'moveToNewOrder',
  REDIRECT_TO_PAGE = 'redirectToPage',
  REFETCH_PROJECT_ARTICLE = 'refetchProjectArticle',

  // Showroom
  LOGO_CLICKED = 'logoClicked',
}
