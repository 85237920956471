import AppLogo from 'components/AppLogo';
import { TestIds } from 'models/enums/test-ids.enum';
import React from 'react';

interface PublicPageLayoutProps {
  title: string;
  subtitle?: string;
}

const PublicPageLayout: React.FC<PublicPageLayoutProps> = ({ title, subtitle, children }) => {
  return (
    <div className="container pt-lg-7">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-6 my-5">
          <div className="mb-0 mb-lg-6">
            <AppLogo publicPage />
          </div>
          <div className="mb-5">
            <h1 className="display-4 text-center mt-5 mb-3" data-testid={TestIds.PUBLIC_PAGE_TITLE}>
              {title}
            </h1>
            {subtitle && (
              <p className="text-muted text-center" data-testid={TestIds.PUBLIC_PAGE_SUBTITLE}>
                {subtitle}
              </p>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PublicPageLayout;
