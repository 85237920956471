import { unwrapResult } from '@reduxjs/toolkit';
import { Space } from 'models/spaces/space.class';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store';
import { fetchAllSpaces } from 'store/spaces/SpacesActions';

export const useAllSpaces = () => {
  const dispatch = useAppDispatch();
  const _fetchSpaces = () => dispatch(fetchAllSpaces());

  const [allSpaces, setAllSpaces] = useState<Space[]>([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const response = await _fetchSpaces();
      if (response.payload) {
        setAllSpaces(unwrapResult(response));
      }
      setLoading(false);
    })();
  }, []);

  return {
    allSpaces,
    loading,
  };
};
